import React from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { CreateURL, ImageURL } from "../component/config/config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tostS, tostE } from "../toast";
const imagePattern = ImageURL + "assets/images/patterns-img.png";

const CreatePassword = () => {
  let { token } = useParams();
  console.log(token);
  //For a validation
  const validationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .min(
        8,
        "Password must contain at least 8 characters including minimum 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character"
      )
      .max(50, "Too Long!!")
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,50}$/,
        "Must Contain One Uppercase, One Lowercase,One Number,One Symbol"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Password must match")
      .required("Confirm Password is required"),
  });
  const navigate = useNavigate();

  // For submitting a form
  const onSubmit = async (values) => {
    console.log(values);
    try {
      const item = {
        ...values,
        token: token,
      };
      const response = await axios.get(CreateURL, { params: item });
      const message = response.data.message;
      console.log(message);
      if (response.status === 200) {
        tostS(response.data.message);
        setTimeout(() => navigate("/login"), 1000);
      }
    } catch (error) {
      const message1 = error.response.data.message;
      console.log(message1);
      tostE(error.response.data.message);
    }
  };
  let { submitCount, errors, touched, setFieldValue, handleSubmit, values } =
    Formik;

  return (
    <>
      <Header />
      <div className="wraper-inner padding-head">
        <div className="inner-pages">
          <div className="patterns-banner inner-space">
            <figure>
              <img src={imagePattern} />
            </figure>
          </div>
          <section className="main-wrapper-login mar-t mb-5">
            <div className="container">
              <div className="form-box row">
                <div className="form-head text-center">
                  <h2>Create password</h2>
                  <p>Create a new password</p>
                </div>
                <Formik
                  initialValues={{ newPassword: "", confirmPassword: "" }}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {(formik) => {
                    return (
                      <Form className="col-md-5 m-auto">
                        <div className="mb-3">
                          <label className="form-label">New Password</label>
                          <Field
                            type="password"
                            name="newPassword"
                            placeholder="New Password"
                            required
                            className={`form-control ${
                              submitCount > 0
                                ? errors?.newPassword && touched?.newPassword
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          />
                          <span className="text-danger">
                            <ErrorMessage name="newPassword" />
                          </span>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Confirm Password</label>
                          <Field
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            required
                            className={`form-control ${
                              submitCount > 0
                                ? errors?.confirmPassword &&
                                  touched?.confirmPassword
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          />
                          <span className="text-danger">
                            <ErrorMessage name="confirmPassword" />
                          </span>
                        </div>
                        <div className="mb-3 text-center mt-5">
                          <button
                            type="submit"
                            className="btn purple-btn min-height hvr-sweep-to-right"
                          >
                            Reset
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </section>
        </div>
        {/* <ToastContainer /> */}
      </div>
      <Footer />
    </>
  );
};

export default CreatePassword;
