import { SignUp, ChefDetail, AddDish, UploadDocument, AddPayment } from '../Actions/Types';

import { CleanSignUpData } from '../Actions/Types';





const Signup = (state, action) => {
    if (typeof state === 'undefined') {
        return {
            data: {},
            error: {},

        }
    }

    switch (action.type) {
        case CleanSignUpData:
            // console.log(action)
            return {
                ...state,
                data: {},
                error: {}
            }
        case SignUp:
            return {
                ...state,
                data: action.data,
            }
        case ChefDetail:
            return {
                ...state,
                data: action.data,
            }

        case AddDish:
            return {
                ...state,
                data: action.data,
            }

        case UploadDocument:
            return {
                ...state,
                data: action.data,
            }

        case AddPayment:
            return {
                ...state,
                data: action.data,
            }

        default:
            return state






    }
}



export default Signup;