import React, { useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import OutSideClick from "../hooks/OutSideClick";
import { ImageURL } from "./config/config";
const group = ImageURL + "assets/images/Group 92.png";
const logo1 = ImageURL + "assets/images/logo4.png";

const Header = () => {
  const navigate = useNavigate();
  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );
  const SignupData = useSelector((state) =>
    state.Signup.data ? state.Signup.data : {}
  );

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    // console.log(boxOutsideClick,"click")
  };
  const handleClose = () => {
    setOpen(false);
  };
  const logout = () => {
    localStorage.clear();
    window.location.replace("/");
  };
  let { pathname } = useLocation();

  // console.log(boxOutsideClick,"boxOutsideClick")
  // console.log(open,"open")
  return (
    <>
      <header className="topHeader" id="fixed-header">
        <div className="container">
          <div>
            <nav className="navbar navbar-expand-lg ">
              <div className="navbar-inner-box">
                <div className="nav-L">
                  <NavLink className="navbar-brand desktop-view-logo" to="/">
                    <img src={group} />
                  </NavLink>

                  <NavLink
                    className="navbar-brand mob-logo-view "
                    style={{ display: "none" }}
                    to="/"
                  >
                    <img src={logo1} />
                  </NavLink>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="nav-R">
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleClickOpen}
          >
            <span className="navbar-toggler-icon">
              <i className="ri-menu-line"></i>
            </span>
          </button>
          <div
            className={
              open
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }
            id="navbarSupportedContent"
          >
            <a
              style={{ cursor: "pointer" }}
              className="closebtn"
              id="close"
              onClick={handleClose}
            >
              ×
            </a>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className={
                    pathname === "/about-us" ? "nav-link active" : "nav-link"
                  }
                  onClick={handleClose}
                  aria-current="page"
                  to="about-us"
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    pathname === "/catering-services"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current="page"
                  onClick={handleClose}
                  to="catering-services"
                >
                  Catering Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    pathname === "/contact-us" ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                  onClick={handleClose}
                  to="contact-us"
                >
                  Contact Us
                </NavLink>
              </li>
              {LoginData.token || SignupData.token ? (
                <li className="nav-item" onClick={() => logout()}>
                  <a className="nav-link " aria-current="page">
                    Logout
                  </a>
                </li>
              ) : (
                <li className="nav-item">
                  <NavLink
                    className={
                      pathname === "/login" ? "nav-link active" : "nav-link"
                    }
                    onClick={handleClose}
                    aria-current="page"
                    to="login"
                  >
                    Login as Chef
                  </NavLink>
                  <NavLink
                    className={
                      pathname === "/signup" ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                    onClick={handleClose}
                    to="signup"
                  >
                    Sign Up as Chef
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
