import React, { useState, useEffect } from 'react'
import Testimonial from '../Testimonial'
import aboutBanner from '../../assets/images/about-banner.png'
import shape4 from '../../assets/images/shape-4.png'
import shape5 from '../../assets/images/shape-5.png'
import aboutChef from '../../assets/images/about-chef.png'
import icon1 from '../../assets/images/icon1.png'
import icon2 from '../../assets/images/icon2.png'
import icon3 from '../../assets/images/icon3.png'
import aboutBottom from '../../assets/images/about-bottom-img.png'
import { AboutURL } from '../config/config'
import axios from '../config/axios'
import { Typography } from "@material-ui/core";


const AboutusUser = () => {
    const [aboutUser, setAboutUser] = useState();

    const apiFun = async () => {
        return
    }
    const aboutUserFetch = async () => {
        console.log("aayaaa")
        const resp = await axios().get(AboutURL);
        console.log(resp)

        setAboutUser(resp.data.data)

    }
    useEffect(() => {
        aboutUserFetch()
    }, [])
    return (
        <>

            {/* <div className="wraper-inner padding-head"> */}
            <div className="inner-pages">
                <div className="inner-banner inner-space">
                    <figure><img src={aboutBanner} /></figure>
                    <div className="container">
                        <div className="banner-inner-content ">
                            <h4>About Us</h4>
                        </div>
                    </div>
                </div>
                <section className="about-us-description space-similer">
                    <span className="shape-4"><img src={shape4} /></span>
                    <span className="shape-5"><img src={shape5} /></span>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <figure><img src={aboutChef} /></figure>
                            </div>
                            <div className="col-md-7 ps-4">
                                <div className="similer-title-head text-center mb-4">
                                    <span className="sub-title">Our story</span>
                                    <h2>About us</h2>
                                </div>
                                <div className="pt-5 about-us-content">
                                    <p className="pinq-text text-center">
                                        <Typography style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: aboutUser?.aboutUsH1 }} variant={"h6"}></Typography>

                                    </p>
                                    <p className="text-center">
                                        <Typography style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: aboutUser?.descriptionOne }} variant={"h6"}></Typography>

                                    </p>
                                </div>
                                <div className="row about-steps mt-5">
                                    <div className="col-md-4">
                                        <div className="about-steps-box">
                                            <figure><img src={icon1} /></figure>
                                            <figcaption className="text-center">
                                                <h4>
                                                    <Typography style={{ fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: aboutUser?.iconH1 }} ></Typography>
                                                </h4>
                                            </figcaption>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="about-steps-box">
                                            <figure><img src={icon2} /></figure>
                                            <figcaption className="text-center">
                                                <h4>
                                                    <Typography style={{ fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: aboutUser?.iconH2 }} ></Typography>
                                                </h4>
                                            </figcaption>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="about-steps-box">
                                            <figure><img src={icon3} /></figure>
                                            <figcaption className="text-center">
                                                <h4>
                                                    <Typography style={{ fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: aboutUser?.iconH3 }} ></Typography>
                                                </h4>
                                            </figcaption>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-bottom space-similer">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 about-bottom-L">
                                <figure><img src={aboutBottom} /></figure>
                            </div>
                            <div className="col-md-6 about-bottom-R">
                                <h3>
                                    <Typography style={{ textAlign: "left", fontWeight: "bold", fontSize: "35px", color: "#8d126e" }} dangerouslySetInnerHTML={{ __html: aboutUser?.aboutUsH2 }} variant={"h6"} ></Typography>

                                </h3>
                                <p>
                                    <Typography style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: aboutUser?.descriptionTwo }} variant={"h6"}></Typography>

                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
            </div>
            {/* </div> */}
        </>
    )
}

export default AboutusUser