import React, { useEffect, useState } from "react";
import ChefStep from "../component/ChefStep";
import Header from "../component/Header";
import Footer from "../component/Footer";
import axios from "axios";
import { AddPaymentURL } from "../component/config/config";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AddPaymentAction } from "../Actions/ActionCreators";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tostS, tostE } from "../toast";
import { useFormik, Form, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";

const Payment = () => {
  const userToken = useSelector((state) =>
    state.login && state.login.data & state.login.data.token
      ? state.login.data.token
      : null
  );
  const dispatch = useDispatch();
  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );
  const SignupData = useSelector((state) =>
    state.Signup.data ? state.Signup.data : {}
  );
  const id =
    LoginData.chefDetails && LoginData.chefDetails._id
      ? LoginData.chefDetails._id
      : SignupData.chefDetails && SignupData.chefDetails._id
      ? SignupData.chefDetails._id
      : 0;
  const ch =
    LoginData.chefDetails && LoginData.chefDetails.addDishComplete
      ? LoginData.chefDetails.addDishComplete
      : SignupData.chefDetails && SignupData.chefDetails.addDishComplete
      ? SignupData.chefDetails.addDishComplete
      : false;

  let navigate = useNavigate();
  useEffect(() => {
    if (!ch) {
      navigate("/");
    }
  }, []);
  const myValidationSchema = Yup.object().shape({
    bankAccountHolderName: Yup.string()
      .min(5, "Holder Name must be at least 5 characters")
      .max(20, "Holder Name must not be more than 20 characters")
      .required("Account Holder Name is required"),
    bankAccountNumber: Yup.string()
      .min(11, "Account Number must be atleast 11 number")
      .max(16, "Account Number must not be more than 16 number")
      .required("Account Number is required"),
    bankRoutingNumber: Yup.string()
      .min(9, "Routing Number must be 9 digits")
      .max(9, "Routing Number must not be more than 9 digits")
      .required("Routing Number is required"),
  });
  const formik = useFormik({
    initialValues: {
      bankAccountHolderName: "",
      bankAccountNumber: "",
      bankRoutingNumber: "",
      id: id,
    },
    validationSchema: myValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await axios.post(AddPaymentURL, { id: id, ...values });
        console.log(response);
        if (response.status === 200) {
          dispatch(AddPaymentAction(response.data.data));
          navigate("/welcome");
          localStorage.setItem("token", response.data.data.token);
          const message = response.data.message;
          tostS(message);
        }
      } catch (error) {
        tostE(error.response.data.message);
      }
    },
  });

  let {
    submitCount,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    values,
    setValues,
  } = formik;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <ChefStep />
          <div className="mar-t mb-5">
            <div className="container">
              <div className="form-box row mb-5 pb-5">
                <div className="form-head text-center">
                  <h2>Add payment Information</h2>
                </div>
                <small className="sub-title-inner">
                  Please enter bank details for payment of dishes served
                </small>
                <div className="combine-group d-flex ">
                  <div className="mb-3">
                    <label className="form-label">
                      Bank Account Holder Name
                    </label>
                    <Field
                      type="text"
                      name="bankAccountHolderName"
                      value={values.bankAccountHolderName.replace(/ +/g, " ")}
                      placeholder="Bank Account Holder Name"
                      className={`form-control ${
                        submitCount > 0
                          ? errors?.bankAccountHolderName &&
                            touched?.bankAccountHolderName
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    />
                    <span className="text-danger">
                      <ErrorMessage name="bankAccountHolderName" />
                    </span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Bank Account Number</label>
                    <Field
                      type="text"
                      name="bankAccountNumber"
                      value={values.bankAccountNumber.replace(/ +/g, " ")}
                      placeholder="Bank Account Number"
                      className={`form-control ${
                        submitCount > 0
                          ? errors?.bankAccountNumber &&
                            touched?.bankAccountNumber
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    />
                    <span className="text-danger">
                      <ErrorMessage name="bankAccountNumber" />
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Bank Routing Number</label>
                  <Field
                    type="text"
                    name="bankRoutingNumber"
                    value={values.bankRoutingNumber.replace(/ +/g, " ")}
                    placeholder="Bank Routing Number"
                    className={`form-control ${
                      submitCount > 0
                        ? errors?.bankRoutingNumber &&
                          touched?.bankRoutingNumber
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  />
                  <span className="text-danger">
                    <ErrorMessage name="bankRoutingNumber" />
                  </span>
                </div>
                <div className="text-center mt-5 d-flex align-items-center justify-content-center">
                  <NavLink
                    type="button"
                    className="btn purple-btn min-height hvr-sweep-to-right trans-btn me-3 a"
                    to={userToken !== null ? "/dashboard/welcome" : "/welcome"}
                  >
                    Skip for Now
                  </NavLink>
                  <button
                    type="submit"
                    className="btn purple-btn min-height hvr-sweep-to-right me-3"
                    data-bs-toggle="modal"
                    data-bs-target="#next-step"
                  >
                    Next
                  </button>
                  <a
                    className="btn min-height black-btn  hvr-sweep-to-right"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
      {/* </div> */}
      {/* <ToastContainer /> */}
      {/* // </div> */}
      <Footer />
    </>
  );
};

export default Payment;
