import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import DashBoardLayout from '../Layouts/dashBoardLayout'
import DashboardRoutes from './DashboardRoutes'
import LoginRoutes from './LoginRoutes'

const Routing = () => {
    const authToken = useSelector(state => state.login.data.token)

    return (
        <>
            {authToken !== "" && authToken !== null && authToken !== undefined ? <DashboardRoutes /> : <LoginRoutes />}



        </>
    )
}

export default Routing