export const SignUp = "SignUp";
export const ChefDetail = "ChefDetail";
export const AddDish = "AddDish";
export const UploadDocument = "UploadDocument";
export const AddPayment = "AddPayment";
export const CleanSignUpData = "CleanSignUpData";

export const LOADING = 'LOADING';
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SET_USER = 'SET_USER';
export const REMEMBER_USER = 'REMEMBER_USER';

export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REFRESH_TOKEN_LOADING = "REFRESH_TOKEN_LOADING";
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const CLEAN_LOGIN_DATA = 'CLEAN_LOGIN_DATA'


export const CLEAN_ALL_DATA = 'CLEAN_ALL_DATA';


export const COMMISSION = "COMMISSION";
export const TIMEZONE = "TIMEZONE";
