import React, { useEffect, useState } from "react";
import LogoImage from "../../assets/images/logo 2.png";
import {
  CompletedOrderURL,
  OpenOrderURL,
  OrderPrepareURL,
  UpdateOrderURL,
  PrintURL,
  LoginChefURL,
} from "../../component/config/config";
import { NewOrderURL } from "../../component/config/config";
import { Dialog } from "@mui/material";
import { ImageURL } from "../../component/config/config";
import { AcceptOrderURL } from "../../component/config/config";
import { RejectOrderURL } from "../../component/config/config";
import moment from "moment";
import Pagination from "./Pagination";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { tostS, tostE } from "../../toast";
import * as Yup from "yup";
import { FormikProvider, Form, Field, ErrorMessage, useFormik } from "formik";
import Search from "./Search";
import axiosInstance from "../config/axios";
import images from "../../assets/images/images.png";

const NewOrder = () => {
  const current_date = new Date();
  const [isDisabled, setIsDisabled] = useState(false);
  const { type } = useParams();
  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails
      ? state.login.data.chefDetails._id
      : null
  );
  const location = useLocation();
  const PostPerPage = JSON.parse(localStorage.getItem("pageSize"));
  //For Dialog box to click on next button
  const [currentTab, setCurrentTab] = useState(type);
  const [newOrder, setNewOrder] = useState([]);
  const [order, setOrder] = useState([]);
  const [completedOrder, setCompletedOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState();
  const [totalData, setTotalData] = useState(0);
  const [food, setFood] = useState([]);
  const [search, setSearch] = useState("");
  const [error, setError] = useState({
    reason: false,
  });
  const [rejection, setRejection] = useState({
    id: "",
    reason: "",
  });
  const [open, setOpen] = useState(false);
  const handleClickOpen = (ids) => {
    setOpen(true);
    setFieldValue("id", ids);
  };

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };
  const [print, setPrint] = useState({
    show: false,
    data: null,
  });
  // const [count, setCount] = useState([])
  // const buttonChange = (i) => {
  //     let arr = [...count]
  //     console.log(arr)
  //     let prev = arr[i] ? arr[i] : 0
  //     arr[i] = prev + 1
  //     setCount(arr)
  // }
  const [active, setActive] = useState(false);
  // const handleOpen = (data, ids) => {
  //     axiosInstance().post(PrintURL, { id: ids })
  //         .then((prin) => {
  //             if (prin.status === 200) {
  //                 setPrint({
  //                     show: true,
  //                     data: data
  //                 })
  //             }
  //         })
  //         .catch((error) => {
  //             console.log(error)
  //         })
  //     // setActive(!active);

  // }
  const handleOpen = (data) => {
    setPrint({
      show: true,
      data: data,
    });
    console.log(data);
  };

  const handleClickClose = () => {
    setPrint({
      show: false,
      data: null,
    });
  };

  const paginate = (pageNumber) => {
    console.log(pageNumber, type);
    if (type == "new") {
      newOrderToday(pageNumber);
    }
    if (type == "open") {
      fetchData(pageNumber);
    }
    if (type == "completed") {
      completeOrder(pageNumber);
    }
    if (type == "food") {
      foodPrepare(pageNumber);
    }
    setCurrentPage(pageNumber);
    console.log("pageNumber", pageNumber);
  };
  const onNext = () => {
    setCurrentPage(currentPage + 1);
    if (type == "new") {
      newOrderToday(currentPage + 1);
    }
    if (type == "open") {
      fetchData(currentPage + 1);
    }
    if (type == "completed") {
      completeOrder(currentPage + 1);
    }
    if (type == "food") {
      foodPrepare(currentPage + 1);
    }
  };

  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
    if (type == "new") {
      newOrderToday(currentPage - 1);
    }
    if (type == "open") {
      fetchData(currentPage - 1);
    }
    if (type == "completed") {
      completeOrder(currentPage - 1);
    }
    if (type == "food") {
      foodPrepare(currentPage - 1);
    }
  };
 const options = Intl.DateTimeFormat().resolvedOptions();
 const timezone = options.timeZone;
  
  const fetchData = async (cp, searchString) => {
    
    document.body.classList.add("loading-indicator");

    setLoading(true);
    try {
      const response = await axiosInstance().post(OpenOrderURL, {
        pageSize: PostPerPage ? PostPerPage : postsPerPage,
        pageNumber: cp,
        searchItem: searchString,
       
      });
      const d = response.data.data;
      document.body.classList.remove("loading-indicator");
      setOrder(d);
      // console.log(d)
      setTotalData(response.data.total_records);
      setLoading(false);
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      const message1 = error.response.data.message;
    }
  };
  const [updat, setUpdat] = useState();
  const update = (ids, statu) => {
    console.log(ids);

    axiosInstance()
      .post(UpdateOrderURL, { id: ids, status: statu })

      .then((up) => {
        if (up.status === 200) {
          tostS(up.data.message);
          // setUpdat(up.data.status)
          fetchData(currentPage);
          // setCurrentPage(1)
        }
      })
      .catch((error) => {
        console.log(error);
        tostE(error.up.data.message);
      });
  };
  const completeed = (ids, statu) => {
    console.log(ids);

    axiosInstance()
      .post(UpdateOrderURL, { id: ids, status: statu })

      .then((up) => {
        if (up.status === 200) {
          tostS(up.data.message);
          // setUpdat(up.data.status)
          if (order.length > 1) {
            fetchData(currentPage);
            setCurrentPage(currentPage);
          } else {
            fetchData(currentPage - 1);
            setCurrentPage(currentPage - 1);
          }
          console.log(order.length);
        }
      })
      .catch((error) => {
        console.log(error);
        tostE(error.up.data.message);
      });
  };
  const newOrderToday = async (cp, searchString) => {
    document.body.classList.add("loading-indicator");
    setLoading(true);
    try {
      const res = await axiosInstance().post(NewOrderURL, {
        pageSize: PostPerPage ? PostPerPage : postsPerPage,
        pageNumber: cp,
        searchItem: searchString,
      });
      if (res.status === 200) {
        document.body.classList.remove("loading-indicator");
        setNewOrder(res.data.data);
        setTotalData(res.data.total_records);
        tostS(res.data.message);
      } else {
        document.body.classList.remove("loading-indicator");
      }
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      const message1 = error.res.data.message;
      tostE(error.res.data.message);
    }
  };
  const completeOrder = async (cp, searchString) => {
    document.body.classList.add("loading-indicator");
    setLoading(true);
    try {
      const x = await axiosInstance().post(CompletedOrderURL, {
        pageSize: PostPerPage ? PostPerPage : postsPerPage,
        pageNumber: cp,
        searchItem: searchString,
      });
      document.body.classList.remove("loading-indicator");
      setCompletedOrder(x.data.data);
      setTotalData(x.data.total_records);
      tostS(x.data.message);
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      tostE(error.x.data.message);
    }
  };
  const foodPrepare = async (cp, searchString) => {
    document.body.classList.add("loading-indicator");

    setLoading(true);
    try {
      const re = await axiosInstance().post(OrderPrepareURL, {
        pageSize: PostPerPage ? PostPerPage : postsPerPage,
        pageNumber: cp,
        searchItem: searchString,
        chefId: LoginData,
        timezone: timezone,
      });
      const z = re.data.data;
      setTotalData(re.data.total_records);
      document.body.classList.remove("loading-indicator");
      setFood(z);
      tostS(z.message);
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      tostE(error.z.data.message);
    }
  };
  const getData = async () => {
    document.body.classList.add("loading-indicator");
    try {
      const response = await axiosInstance().get(LoginChefURL, {});
      localStorage.setItem("pageSize", response?.data?.data?.pageSize);
      setPostsPerPage(response?.data?.data?.pageSize);
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    if (type === "new") {
      newOrderToday(currentPage);
    } else if (type === "open") {
      fetchData(currentPage);
    } else if (type === "food") {
      foodPrepare(currentPage);
    } else if (type === "completed") {
      completeOrder(currentPage);
    }
    getData();
  }, [type]);
  //Get current Posts
  const indexOfLastPost = currentPage * PostPerPage;
  const indexOfFirstPost = indexOfLastPost - PostPerPage;

  const accept = (id) => {
    axiosInstance()
      .post(AcceptOrderURL, { id: id })
      .then((re) => {
        if (re.status === 200) {
          tostS(re.data.message);
          newOrderToday(currentPage);
        }
      })
      .catch((error) => {
        tostE(error.re.data.message);
      });
  };
  const validationSchema = Yup.object().shape({
    reason: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(5, " Reason must be at least 5 characters ")
      .max(750, "Reason cannot be more than 750 characters ")
      .required("Reason is required"),
  });
  const formik = useFormik({
    initialValues: {
      reason: "",
      id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const respon = await axiosInstance().post(RejectOrderURL, values);
        console.log(respon);
        if (respon.status === 200) {
          const messa = respon.data.message;
          setOpen(false);
          resetForm();
          newOrderToday(currentPage);
          tostS(messa);
        }
      } catch (error) {
        setIsDisabled(false);
        tostE(error.respon.data.messsage);
      }
    },
  });

  const handleSearch = (e) => {
    e.preventDefault();

    let searchString = e.target.value;

    if (currentTab === "new") {
      newOrderToday(currentPage, searchString);
    }
    if (currentTab === "open") {
      fetchData(currentPage, searchString);
    }
    if (currentTab === "complete") {
      completeOrder(currentPage, searchString);
    }
    if (currentTab === "food") {
      foodPrepare(currentPage, searchString);
    }
    setSearch(searchString);
    setCurrentPage(1);
    // if (search.length > 0) {
    //     // newOrder.filter((sear) => {
    //     //     return sear.orderId.match(search)
    //     // })
    // }
  };

  let {
    submitCount,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    values,
    setValues,
  } = formik;

  const navigate = useNavigate();
  const tod = () => {
    navigate("/dashboard/myorder/new");
    newOrderToday(1);
    // setCurrentTab("new")
  };
  const ope = () => {
    navigate("/dashboard/myorder/open");
    fetchData(1);
    // setCurrentTab("open")
  };
  const foo = () => {
    navigate("/dashboard/myorder/food");
    foodPrepare(1);
    // setCurrentTab("food")
  };
  const com = () => {
    navigate("/dashboard/myorder/completed");
    completeOrder(1);
    // setCurrentTab("complete")
  };
  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>my orders</h3>
        </div>
        <div className="dash-bottom mt-2 my-orders">
          <div className="my-orders-head d-flex align-items-center justify-content-between">
            <ul className="nav nav-tabs my-orders-tabs">
              <li className="nav-item">
                <button
                  onClick={() => {
                    tod();
                    setCurrentTab("new");
                    setCurrentPage(1);
                  }}
                  className={"nav-link" + (type == "new" ? " active" : "")}
                  id="tab-1"
                  type="button"
                >
                  New Orders for Today
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => {
                    ope();
                    setCurrentTab("open");
                    setCurrentPage(1);
                  }}
                  className={"nav-link" + (type == "open" ? " active" : "")}
                  id="tab-2"
                  type="button"
                >
                  Open Orders
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => {
                    foo();
                    setCurrentTab("food");
                    setCurrentPage(1);
                  }}
                  className={"nav-link" + (type == "food" ? " active" : "")}
                  id="tab-3"
                  type="button"
                >
                  Food Preparation
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => {
                    com();
                    setCurrentTab("complete");
                    setCurrentPage(1);
                  }}
                  className={
                    "nav-link" + (type == "completed" ? " active" : "")
                  }
                  id="tab-4"
                  type="button"
                >
                  Completed Orders
                </button>
              </li>
            </ul>
            {/* <Search today={currentTab === "new" ? newOrder : currentTab === "open" ? order : currentTab === "food" ? food : currentTab === "complete" ? completedOrder : ""} /> */}
            {/* <div className="search-box">
                            <input type="text" name="search" placeholder="Search by Order ID" className="form-control" value={search} onChange={(e) => { handleSearch(e) }} />
                            <button className="btn-search"><i className="ri-search-line"></i></button>
                        </div> */}
          </div>
          <div className="my-orders-body">
            {totalData === 0 ? <h3>NO DATA AVAILABLE</h3> : ""}
            <div className="tab-content">
              <div
                className={
                  "tab-pane fade" + (type === "new" ? " show active" : "")
                }
                id="new-order"
                role="tabpanel"
                aria-labelledby="tab-1"
              >
                {totalData > 0 ? (
                  <h3 className="my-orders-title">
                    {moment(new Date()).format("Do MMM YYYY")}
                  </h3>
                ) : (
                  ""
                )}
                {newOrder.map((orde, id) => (
                  <div
                    className="accordion dash-accordion"
                    id="accordionExample"
                    key={id}
                  >
                    <div className="accordion-item">
                      <>
                        <h2 className="accordion-header" id="headingOne">
                          <div className="dish-head">
                            <small>#{orde.orderId}</small>
                            <h5>Dishes</h5>
                          </div>
                          <ul className="Dishes-list dishes-list-edit">
                            {orde.dishData.map((dishes, idx) => (
                              <li key={idx}>
                                <figure className="Dishes-list-L">
                                  <img
                                    src={ImageURL + dishes?.dishId?.dishImage}
                                  />
                                </figure>
                                <figcaption className="Dishes-list-R">
                                  <h4>{dishes?.dishName}</h4>
                                  <span className="pb-2">
                                    <b>Qty:</b> {dishes?.qty}
                                  </span>
                                </figcaption>
                              </li>
                            ))}
                          </ul>
                        </h2>
                      </>
                      <div
                        id={"collapseOne" + id}
                        className="accordion-collapse "
                        aria-labelledby={"headingOne" + id}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul className="Delivery-content">
                            <li
                              className="me-2"
                              style={{ listStyleType: "none" }}
                            >
                              <b>Delivery Preference:</b>
                              {orde.deliveryPreference}
                            </li>
                            <li
                              className="me-2"
                              style={{ listStyleType: "none" }}
                            >
                              <b>Delivery / Pick up Window:</b>
                              {orde.deliveryOrPickupWindow}
                            </li>
                            <li
                              className="me-2"
                              style={{ listStyleType: "none" }}
                            >
                              <b>Customer Name:</b>
                              {orde.userId.fullName}
                            </li>
                            <li
                              className="me-2"
                              style={{ listStyleType: "none" }}
                            >
                              <b>Order Instructions:</b>{" "}
                              {orde.orderInstructions}
                            </li>
                          </ul>
                          <div className="mt-4  btn-group-box">
                            {orde.status === 0 ? (
                              <>
                                <a
                                  className="accpet-btn btn purple-btn min-height hvr-sweep-to-right  me-3"
                                  onClick={() => accept(orde?._id)}
                                >
                                  Accept
                                </a>
                                <a
                                  className="reject-btn btn purple-btn min-height hvr-sweep-to-right trans-btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reject-tab"
                                  onClick={() => handleClickOpen(orde?._id)}
                                >
                                  Reject
                                </a>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>

              {/* //For Open orders */}
              <div
                className={
                  "tab-pane fade" + (type === "open" ? " show active" : "")
                }
                id="open-order"
                role="tabpanel"
                aria-labelledby="tab-2"
              >
                {Object.entries(
                  order.reduce((acc, ord) => {
                    const window = ord.deliveryOrPickupWindow;
                    const date = moment(ord.dishData[0].scheduledDate).format(
                      "Do MMM YYYY"
                    );

                    if (!acc[date]) {
                      acc[date] = {};
                    }

                    if (!acc[date][window]) {
                      acc[date][window] = [];
                    }

                    acc[date][window].push(ord);
                    return acc;
                  }, {})
                ).map(([date, windows]) => (
                  <div key={date} className="scheduled-date-group">
                    {Object.entries(windows)
                      .sort(([a], [b]) => {
                        // Sorting logic to ensure "10am-1pm" appears before "4pm-7pm"
                        if (a === "10am-1pm") return -1;
                        if (b === "10am-1pm") return 1;
                        if (a === "4pm-7pm") return 1;
                        if (b === "4pm-7pm") return -1;
                        return 0;
                      })
                      .map(([window, orders]) => (
                        <div
                          key={window}
                          className="delivery-pickup-window-group"
                        >
                       
                          {orders.map((ord, i) => (
                            <div
                              className="accordion dash-accordion"
                              id={`accordion${i}`}
                              key={i}
                            >
                                 <div className="my-order-title mt-4">
                            <h3
                              className="my-orders-title"
                              style={{
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >
                              {date}
                              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                              {window === "10am-1pm" ? "Lunch" : "Dinner"}
                              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                              Delivery&nbsp;/&nbsp;Pick-Up Window: {window}
                               </h3>
                               </div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id={`heading${i}`}
                                >
                                  <div className="dish-head">
                                    <div className="title-timer">
                                      <small>
                                        #{ord.orderId}{" "}
                                        <b>
                                          {ord.orderPortion
                                            ? ` (${ord.orderPortion})`
                                            : ""}
                                        </b>
                                      </small>
                                    </div>
                                  </div>
                                  <ul className="Dishes-list dishes-list-edit">
                                    {ord.dishData.map((daa) => (
                                      <li key={daa._id}>
                                        <figure className="Dishes-list-L">
                                          <img
                                            src={
                                              ImageURL + daa.dishId?.dishImage
                                            }
                                            alt={daa?.dishName}
                                          />
                                        </figure>
                                        <figcaption className="Dishes-list-R">
                                          <h4>{daa.dishName}</h4>
                                          <span className="pb-2">
                                            <b>Qty:</b> {daa.qty}
                                          </span>
                                          <br />
                                          <span>
                                            <b>Type:</b> {ord.orderType}
                                          </span>
                                        </figcaption>
                                      </li>
                                    ))}
                                  </ul>
                                </h2>
                                <div
                                  id={`collapse${i}`}
                                  className="accordion-collapse"
                                  aria-labelledby={`heading${i}`}
                                  data-bs-parent={`#accordion${i}`}
                                >
                                  <div className="accordion-body">
                                    <ul className="Delivery-content">
                                      <li
                                        className="me-2"
                                        style={{ listStyleType: "none" }}
                                      >
                                        <b>Delivery Preference:</b>{" "}
                                        {ord.deliveryPreference}
                                      </li>
                                      <li
                                        className="me-2"
                                        style={{ listStyleType: "none" }}
                                      >
                                        <b>Delivery / Pick up Window:</b>{" "}
                                        {ord.deliveryOrPickupWindow}
                                      </li>
                                      <li
                                        className="me-2"
                                        style={{ listStyleType: "none" }}
                                      >
                                        <b>Customer Name:</b>{" "}
                                        {ord?.userId?.fullName}
                                      </li>
                                      <li
                                        className="me-2"
                                        style={{ listStyleType: "none" }}
                                      >
                                        <b>Customer Phone Number:</b>{" "}
                                        {ord?.userId?.phoneNumber
                                          ? `(${ord.userId.phoneNumber.slice(
                                              0,
                                              3
                                            )}) ${ord.userId.phoneNumber.slice(
                                              3,
                                              6
                                            )}-${ord.userId.phoneNumber.slice(
                                              6
                                            )}`
                                          : "null"}
                                      </li>
                                      <li
                                        className="me-2"
                                        style={{ listStyleType: "none" }}
                                      >
                                        <b>Order Placement Date:</b>{" "}
                                        {moment(ord.placeOrderDate).format(
                                          "Do MMM YYYY"
                                        )}
                                      </li>
                                      <li
                                        className="me-2"
                                        style={{ listStyleType: "none" }}
                                      >
                                        <b>Order Instructions:</b>{" "}
                                        {ord.orderInstructions}
                                      </li>
                                    </ul>
                                    <div className="mt-4 d-flex three-combine-btn">
                                      {moment(current_date).format(
                                        "Do MMM YYYY"
                                      ) ===
                                      moment(
                                        ord.dishData[0].scheduledDate
                                      ).format("Do MMM YYYY") ? (
                                        <>
                                          <a
                                            className={
                                              active
                                                ? "step-button btn black-btn min-height hvr-sweep-to-right me-3"
                                                : "step-button btn purple-btn min-height hvr-sweep-to-right me-3"
                                            }
                                            data-bs-toggle="modal"
                                            data-bs-target="#Print-label"
                                            onClick={() => handleOpen(ord)}
                                          >
                                            1. Print Label
                                          </a>
                                          <a
                                            className={
                                              ord.status === 1
                                                ? "step-button btn pink-btn min-height hvr-sweep-to-right me-3 disbled-btn"
                                                : "step-button btn pink-btn min-height hvr-sweep-to-right me-3"
                                            }
                                            onClick={() => update(ord._id, 1)}
                                          >
                                            2. Order Ready
                                          </a>
                                        </>
                                      ) : (
                                        <>
                                          <a
                                            className="step-button btn black-btn min-height hvr-sweep-to-right me-3 disbled-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#Print-label"
                                          >
                                            1. Print Label
                                          </a>
                                          <a className="step-button btn pink-btn min-height hvr-sweep-to-right me-3 disbled-btn">
                                            2. Order Ready
                                          </a>
                                        </>
                                      )}
                                      {ord.status === 1 ? (
                                        <a
                                          className="step-button btn min-height black-btn hvr-sweep-to-right"
                                          onClick={() => completeed(ord._id, 2)}
                                        >
                                          3. Order Pick-Up Completed
                                        </a>
                                      ) : (
                                        <a className="step-button btn min-height black-btn hvr-sweep-to-right disbled-btn">
                                          3. Order Pick-Up Completed
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                ))}
              </div>

              {/* // For Food Preparation */}
              <div
                className={
                  "tab-pane fade" + (type === "food" ? " show active" : "")
                }
                id="food-preparation"
                role="tabpanel"
                aria-labelledby="tab-3"
              >
                {Object.entries(
                  food.reduce((acc, ord) => {
                    ord.data.forEach((dish) => {
                      const window = dish.deliveryOrPickupWindow;
                      const date = moment(ord._id).format("Do MMM YYYY");

                      if (!acc[date]) {
                        acc[date] = {};
                      }

                      if (!acc[date][window]) {
                        acc[date][window] = [];
                      }

                      acc[date][window].push(dish);
                    });
                    return acc;
                  }, {})
                ).map(([date, windows]) => (
                  <div key={date} className="scheduled-date-group">
                    {Object.entries(windows)
                      .sort(([a], [b]) => {
                        // Sorting logic to ensure "10am-1pm" appears before "4pm-7pm"
                        if (a === "10am-1pm") return -1;
                        if (b === "10am-1pm") return 1;
                        if (a === "4pm-7pm") return 1;
                        if (b === "4pm-7pm") return -1;
                        return 0;
                      })
                      .map(([window, orders]) => (
                        <div
                          key={window}
                          className="delivery-pickup-window-group"
                        >
                          <div className="my-order-title mt-5">
                            <h3
                              className="my-orders-title"
                              style={{
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >
                              {date}
                              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                              {window === "10am-1pm" ? "Lunch" : "Dinner"}
                              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                              Delivery&nbsp;/&nbsp;Pick-Up Window: {window}
                            </h3>
                          </div>

                          {/* Loop over the filtered orders for this date and window */}
                          {orders
                            .reduce((resultArray, item, index) => {
                              const chunkIndex = Math.floor(index / 4);

                              if (!resultArray[chunkIndex]) {
                                resultArray[chunkIndex] = []; // start a new chunk
                              }

                              resultArray[chunkIndex].push(item);
                              return resultArray;
                            }, [])
                            .map((chunk, chunkIndex) => (
                              <div className="without-tabs" key={chunkIndex}>
                                <div className="dish-head">
                                  <h5>Dishes:</h5>
                                </div>
                                <ul className="Dishes-list dishes-list-edit">
                                  {chunk.map((da, i) => (
                                    <li key={i}>
                                      <figure className="Dishes-list-L">
                                        <img
                                          src={ImageURL + da?.dishImage}
                                          alt={da?.dishName}
                                        />
                                      </figure>
                                      <figcaption className="Dishes-list-R">
                                        <h4>{da?.dishName}</h4>
                                        <span className="pb-2">
                                          <b>Qty:</b> {da?.dishQty}
                                        </span>
                                        <br />
                                        <span>
                                          <b>Time:</b>{" "}
                                          {da.deliveryOrPickupWindow}
                                        </span>
                                      </figcaption>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                ))}
              </div>
              {/* //For Completed orders */}
              <div
                className={
                  "tab-pane fade" + (type === "completed" ? " show active" : "")
                }
                id="completed-orders"
                role="tabpanel"
                aria-labelledby="tab-4"
              >
                {completedOrder.map((com, index) => (
                  <div
                    className="accordion dash-accordion"
                    id="accordionExample"
                  >
                    <h3 className="my-orders-title">
                      {moment(new Date(com?.orderDeliveryDate)).format(
                        "Do MMM YYYY"
                      )}
                    </h3>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#collapseOne" + index}
                          aria-expanded="true"
                          aria-controls={"collapseOne" + index}
                        >
                          <div className="dish-head">
                            <div className="title-timer">
                              <small>
                                #{com?.orderId} <b> {com?.orderPortion}</b>
                              </small>
                            </div>
                          </div>
                          <ul className="Dishes-list dishes-list-edit">
                            {com.dishData.map((co) => (
                              <li>
                                <figure className="Dishes-list-L">
                                  <img src={ImageURL + co?.dishId?.dishImage} />
                                </figure>
                                <figcaption className="Dishes-list-R">
                                  <h4>{co?.dishName}</h4>
                                  <span className="pb-2">
                                    <b>Qty:</b> {co?.qty}
                                  </span>
                                  <br />
                                  <span>
                                    <b>Type:</b> {com?.orderType}
                                  </span>
                                </figcaption>
                              </li>
                            ))}
                          </ul>
                        </button>
                      </h2>
                      <div
                        id={"collapseOne" + index}
                        className="accordion-collapse collapse show"
                        aria-labelledby={"headingOne" + index}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul className="Delivery-content">
                            <li style={{ listStyleType: "none" }}>
                              <b>Delivery Preference:</b>
                              {com?.deliveryPreference}
                            </li>
                            <li style={{ listStyleType: "none" }}>
                              <b>Delivery / Pick up Window:</b>
                              {com.deliveryOrPickupWindow}
                            </li>
                            <li style={{ listStyleType: "none" }}>
                              <b>Customer Name:</b>
                              {com?.userId?.fullName}
                            </li>
                            <li style={{ listStyleType: "none" }}>
                              <b>Order Delivered Date :</b>
                              {moment(com.orderDeliveryDate).format(
                                "DD MMM YYYY"
                              )}
                            </li>
                            <li style={{ listStyleType: "none" }}>
                              <b>Order Instructions:</b> {com.orderInstructions}
                            </li>
                          </ul>
                          <div className="mt-4 d-flex three-combine-btn">
                            <NavLink
                              className=" btn purple-btn min-height hvr-sweep-to-right  me-3"
                              to={`/dashboard/viewdetails/${com?._id}`}
                            >
                              View Details
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            postsPerPage={PostPerPage}
            totalPosts={totalData}
            paginate={(pageNumber) => {
              paginate(pageNumber);
            }}
            onNext={onNext}
            onPrevious={onPrevious}
          />
        </div>
      </div>
      <Dialog open={open}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div className="common-modal space-2">
              <div className="modal-dialog">
                <div className="modal-content">
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <i className="ri-close-circle-line"></i>
                  </button>
                  <div className="modal-head text-center mb-4">
                    <h4 className="modal-title">reject order</h4>
                    <p>Please tell us why you rejected this order</p>
                  </div>
                  <div className="modal-body text-center">
                    <div className="mb-0 reject-text-area">
                      <Field
                        type="text"
                        component="textarea"
                        rows="4"
                        placeholder="Enter reason"
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.reason && touched?.reason
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        name="reason"
                        value={values.reason.replace(/ +/g, " ")}
                      ></Field>
                      <span
                        className="text-danger"
                        style={{ textAlign: "left" }}
                      >
                        <ErrorMessage name="reason" />
                      </span>
                    </div>
                    <div className="mt-5">
                      <button className="btn purple-btn min-height hvr-sweep-to-right">
                        submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Dialog>
      <Dialog open={print.show}>
        <div
          className="common-modal Print-label-box space-2 "
          id="Print-label"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClickClose}
              >
                <i className="ri-close-circle-line"></i>
              </button>
              <div className="modal-head text-center mb-4">
                <figure>
                  <img src={LogoImage} />
                </figure>
              </div>

              <div className="modal-body text-center">
                <div className="Print-label-head">
                  {/* <span className="text-info-print"><b>Order ID: {print.data?.orderId}</b></span> */}
                  <span className="text-info-print">
                    <b>Customer Name: {print.data?.userId?.fullName}</b>
                  </span>
                  <span className="text-info-print">
                    <b>Address: </b>
                    {/* {print?.data?.address1} */}
                    {`${print.data?.userAddress?.add1}` +
                      ", " +
                      `${
                        print?.data?.userAddress?.add2
                          ? print?.data?.userAddress?.add2 + ", "
                          : ""
                      }` +
                      `${print.data?.userAddress?.city}` +
                      ", " +
                      `${print.data?.userAddress?.state}` +
                      ", " +
                      `${print.data?.userAddress?.zip}`}
                  </span>
                  <span className="text-info-print">
                    <b>Phone: </b>
                    {`(${print.data?.userId?.phoneNumber.slice(
                      0,
                      3
                    )}) ${print.data?.userId?.phoneNumber.slice(
                      3,
                      6
                    )}-${print.data?.userId?.phoneNumber.slice(6)} `}
                  </span>
                </div>
                <hr className="grey-line" />

                <div className="Print-label-body">
                  <h4>Order Details:</h4>
                  <span className="text-info-print">
                    <b>Chef Name:</b> {print.data?.chefId?.fullName}
                  </span>
                  <div className="d-flex">
                    <span className="text-info-print">
                      <b>Delivery Preference:</b>{" "}
                      {print.data?.deliveryPreference}
                    </span>
                    <span className="text-info-print">
                      <b>Delivery window: </b>{" "}
                      {print.data?.deliveryOrPickupWindow}
                    </span>
                  </div>
                  {print.data && print.data.dishData && (
                    <table className="print-info-table">
                      <thead>
                        <tr>
                          <th>Dish Name</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {print.data.dishData.map((daa, index) => (
                          <tr key={index}>
                            <td>{daa?.dishId?.name}</td>
                            <td>{daa?.qty}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {/* {print.data &&
                      print.data.dishData &&
                      print.data.dishData.map((daa) => (
                        <ul className="print-info-table">
                          <li>
                            <h3>Dish Name</h3>
                            <span>{daa?.dishId?.name}</span>
                          </li>
                          <li>
                            <h3>Quantity</h3>
                            <span>{daa?.qty}</span>
                          </li>
                        </ul>
                      ))} */}
                </div>
                <p>
                  <strong>Note : </strong>Please write this on sticky note
                </p>

                {/* <div className="mt-5"><button className="btn purple-btn min-height hvr-sweep-to-right" >DOWNLOAD</button></div> */}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* <div className="screen-div-info">
                <ul className="info-list">
                    <li>
                        <a href="tel:9513539491">
                            <i className="ri-phone-line"></i>951-353-9491
                        </a>
                    </li>
                    <li>
                        <a href="mailto:info@email.com">
                            <i className="ri-mail-line"></i>info@email.com
                        </a>
                    </li>
                </ul>
            </div> */}
    </>
  );
};

export default NewOrder;
