import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/all.css";
import "./assets/fonts/remixicon.css";
import "./assets/css/animation.css";
import "./assets/css/style.css";
import "./assets/css/root-css.css";
import "./assets/css/responsive.css";
import "./assets/css/owl.theme.css";
import "react-tagsinput/react-tagsinput.css";
import ReactGA from "react-ga4";
// import useSecretManager from "./hooks/SecretManager";

ReactGA.initialize([{ trackingId: process.env.REACT_APP_TRACKING_ID }])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
// const AppWithTracking = () => {
//   const trackingId = useSecretManager();
//   useEffect(() => {
//     if (trackingId) {
//       ReactGA.initialize([{ trackingId: trackingId.TRACKING_ID }]);
//     }
//   }, [trackingId]);

//   return (
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   );
// };

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<AppWithTracking />);
