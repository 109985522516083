import React, { useState } from "react";
import ChefStep from "../component/ChefStep";
import Footer from "../component/Footer";
import { Dialog } from "@material-ui/core";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginSuccess } from "../Actions/ActionCreators";
import { CleanSignUpDataAction } from "../Actions/ActionCreators";
import { useDispatch } from "react-redux";
import { ImageURL } from "../component/config/config";
const ImageSlider = ImageURL + "assets/images/slider-img.png";
const chefImage = ImageURL + "assets/images/chef-img.png";

const Welcome = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let DataSoFar = useSelector((state) => state.Signup.data);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleMoveon = () => {
    dispatch(loginSuccess(DataSoFar));
    dispatch(CleanSignUpDataAction());
    navigate("/dashboard");
  };

  return (
    <>
      {/* <Header /> */}
      {/* <div className="wraper-inner padding-head"> */}
      <div className="inner-pages">
        {/* <div className="patterns-banner inner-space">
                        <figure><img src={background} /></figure>
                    </div> */}
        <ChefStep />
        <div className="mar-t mb-5">
          <div className="container">
            <div className="form-box row mb-5 pb-5">
              <div className="form-head text-center">
                <h2>WELCOME TO WANNA EAT!!</h2>
              </div>
              <div className="welcome-box text-center col-md-4 m-auto">
                <figure>
                  <img src={chefImage} />
                </figure>
                <h3>
                  congratulations!! <br />
                  you are registered as a chef
                </h3>
                {/* <p>Please click continue to view the tutorials and get familiar with Wanna Eat</p> */}
                <div className="text-center mt-4">
                  {" "}
                  <a
                    onClick={handleMoveon}
                    className="btn purple-btn hvr-sweep-to-right min-height"
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <Footer />
      <Dialog open={open}>
        <div className="common-modal space-2">
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <button type="button" className="close-btn" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-circle-line"></i>
                            </button> */}
              <div className="modal-head text-center mb-3">
                <h4 className="modal-title">tutorials</h4>
              </div>
              <div className="modal-body slider-box-content">
                <div className="slider-box">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src={ImageSlider} className="d-block w-100"></img>
                      </div>
                      <div className="carousel-item">
                        <img src={ImageSlider} className="d-block w-100"></img>
                      </div>
                      <div className="carousel-item">
                        <img src={ImageSlider} className="d-block w-100"></img>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                <h4>Lorem ipsum sit amet</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </p>
                <div className="mb-3 text-center mt-4 d-flex align-items-center justify-content-between">
                  <button
                    type="button"
                    onClick={() => handleMoveon()}
                    className="btn purple-btn min-height hvr-sweep-to-right trans-btn me-3"
                  >
                    Skip For Later
                  </button>
                  <button
                    type="button"
                    onClick={() => handleMoveon()}
                    className="btn purple-btn min-height hvr-sweep-to-right"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Welcome;
