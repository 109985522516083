import store from '../../Store/Store';
const authHeader = () => {
    const userToken = store.getState().login.data.token || store.getState().Signup.data.token;

    // const token = localStorage.getItem("token");
    if (userToken) {
        return { authorization: "Bearer " + userToken };
    }
    return {};
};
export default authHeader;

