export const convertStringToPascalCase = (inputString) => {
  let words = inputString.split(/(\s|\(|\[|\)|\])/); // Include closing parentheses/brackets as separators too.

  return words
    .map((word, index) => {
      // Ignore empty strings or purely separator strings.
      if (word.trim() === "" || ["(", "[", ")", "]"].includes(word)) {
        return word; // Return separators as-is.
      }

      // Format words: first letter uppercase, rest lowercase.
      return word.length > 0
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word;
    })
    .join(''); // Rejoin without adding unintended characters.
};





export const handleFormattedInputChange = ({
  event,
  fieldName,
  formatterFunction,
  setFieldValue,
}) => {
  const inputElement = event.target;
  const cursorPosition = inputElement.selectionStart;
  const rawValue = event.target.value.replace(/ +/g, " ");
  const formattedValue = formatterFunction(rawValue);
  const positionAdjustment = formattedValue.length - rawValue.length;
  setFieldValue(fieldName, formattedValue);
  setTimeout(() => {
    inputElement.setSelectionRange(
      cursorPosition + positionAdjustment,
      cursorPosition + positionAdjustment
    );
  }, 0);
};
