import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import termsBanner from './../assets/images/terms-banner.png'
import { PrivacyPolicyURL } from './config/config'
import axios from 'axios'
import { Typography } from "@material-ui/core";

const Privacy = () => {
  const [privacy, setPrivacy] = useState()
  const privacyFetch = async () => {
    const response = await axios.post(PrivacyPolicyURL, { contentType: "Privacy policy" })
    setPrivacy(response.data.data)
  }
  useEffect(() => {
    privacyFetch()
  }, [])
  return (
    <>
      {console.log(privacy)}
      {/* <Header /> */}
      <div className="wraper-inner padding-head">
        <div className="inner-pages">
          <div className="inner-banner inner-space">
            <figure><img src={termsBanner} /></figure>
            <div className="container">
              <div className="banner-inner-content ">
                <h4>Privacy Policy</h4>
              </div>
            </div>
          </div>

          <div className="terms-policy-content space-similer">
            <div className="container">
              <div className="terms-policy-content-box policy" >
                {/* <h4>INTRODUCTION</h4> */}
                <p>
                  <Typography dangerouslySetInnerHTML={{ __html: privacy?.privacyPolicyText }} variant="h6" ></Typography>
                </p>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in </p> */}
              </div>
              {/* )
              })} */}

            </div>
          </div>

        </div>
      </div>
      <Footer />

    </>

  )
}

export default Privacy