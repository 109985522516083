import React, { useState } from 'react'
import { useRoutes, Navigate, useLocation } from 'react-router-dom'
import AboutUs from '../component/AboutUs'
import ContactUs from '../component/ContactUs'
import Faq from '../component/Faq'
import Privacy from '../component/Privacy'
import Refund from '../component/Refund'
import Terms from '../component/Terms'
import ChefDetails from '../pages/ChefDetails'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Payment from '../pages/Payment'
import ResetPassword from '../pages/ResetPassword'
import SignUp from '../pages/SignUp'
import Upload from '../pages/Upload'
import UploadDish from '../pages/UploadDish'
import Welcome from '../pages/Welcome'
import Layout from '../Layouts'
import ChefTerms from '../component/ChefTerms'
import CreatePassword from '../pages/CreatePassword'
import AboutusUser from '../component/app/AboutusUser'
import PrivacyPolicyUser from '../component/app/PrivacyPolicyUser'
import TUser from '../component/app/T&CUser';
import CateringService from '../component/CateringService'

const LoginRoutes = () => {
    // const addDish = SignupData.chefDetails.addDishComplete === true
  
    return useRoutes([
      {
        path: "/",
        element: <Layout />,
        children: [
          { path: "", element: <Home /> },
          { path: "login", element: <Login /> },
          { path: "signup", element: <SignUp /> },
          { path: "about-us", element: <AboutUs /> },
          { path: "catering-services", element: <CateringService /> },
          { path: "contact-us", element: <ContactUs /> },
          { path: "faqs", element: <Faq /> },
          { path: "terms", element: <Terms /> },
          { path: "cheft&c", element: <ChefTerms /> },
          { path: "privacy-policy", element: <Privacy /> },
          { path: "refund", element: <Refund /> },
          { path: "chefdetails", element: <ChefDetails /> },
          { path: "adddish", element: <UploadDish /> },
          { path: "upload", element: <Upload /> },
          { path: "payment", element: <Payment /> },
          { path: "welcome", element: <Welcome /> },
          { path: "reset/:token", element: <ResetPassword /> },
          { path: "createPassword/:token", element: <CreatePassword /> },
          // { path: "*", element: <Navigate to='/home' replace /> }
        ],
      },
      {
        path: "user",
        children: [
          { path: "privacy", element: <PrivacyPolicyUser /> },
          { path: "aboutususer", element: <AboutusUser /> },
          { path: "termsuser", element: <TUser /> },
        ],
      },
      { path: "/*", element: <Navigate to="/" replace /> },
    ]);
}

export default LoginRoutes