import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import termsBanner from './../assets/images/terms-banner.png'
import { TermsURL } from './config/config'
import axios from 'axios'
import { Typography } from "@material-ui/core";

const Terms = () => {
  const [term, setTerm] = useState()
  const termFetch = async () => {
    const response = await axios.post(TermsURL)
    setTerm(response.data.data)
  }
  useEffect(() => {
    termFetch()
  }, [])
  return (
    <>
      {/* <Header /> */}
      <div class="wraper-inner padding-head">
        <div class="inner-pages">
          <div class="inner-banner inner-space">
            <figure><img src={termsBanner} /></figure>
            <div class="container">
              <div class="banner-inner-content ">
                <h4>Terms & Conditions</h4>
              </div>
            </div>
          </div>

          <div class="terms-policy-content space-similer">
            <div class="container">

              <div class="terms-policy-content-box">
                {/* <h4>INTRODUCTION</h4> */}
                <p>
                  <Typography dangerouslySetInnerHTML={{ __html: term?.termsOfServiceText }} variant="h6" ></Typography>
                </p>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in </p> */}
              </div>

            </div>
          </div>

        </div>
      </div>
      <Footer />

    </>

  )
}

export default Terms