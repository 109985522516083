import React, { useState, useEffect } from 'react'
import termsBanner from '../../assets/images/terms-banner.png'
import axios from '../config/axios'
import { PrivacyPolicyURL } from '../config/config'
import { Typography } from "@material-ui/core";

const PrivacyPolicyUser = () => {
    const [privacyUser, setPrivacyUser] = useState()
    const privacyFetchUser = async () => {
        const response = await axios().post(PrivacyPolicyURL, { contentType: "User Privacy policy" })
        setPrivacyUser(response.data.data)
    }
    useEffect(() => {
        privacyFetchUser()
    }, [])

    return (
        <>
            {/* <div className="wraper-inner padding-head"> */}
            <div className="inner-pages">
                <div className="inner-banner inner-space">
                    <figure><img src={termsBanner} /></figure>
                    <div className="container">
                        <div className="banner-inner-content ">
                            <h4>Privacy Policy</h4>
                        </div>
                    </div>
                </div>

                <div className="terms-policy-content space-similer">
                    <div className="container">

                        <div className="terms-policy-content-box policy">
                            {/* <h4>INTRODUCTION</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in </p> */}
                            {/* <p> */}
                            <Typography dangerouslySetInnerHTML={{ __html: privacyUser?.userPrivacyPolicyText }} variant={"h6"}></Typography>
                            {/* </p> */}
                        </div>
                    </div>
                </div>

            </div>
            {/* </div> */}

        </>
    )
}

export default PrivacyPolicyUser