import React, { useEffect } from 'react'
import { NavLink } from "react-router-dom";
import { AllChefReviews } from '../../component/config/config';
import moment from 'moment';
import ReactStars from "react-rating-stars-component";
import ProgressBar from 'react-bootstrap/ProgressBar';
import axiosInstance from "../config/axios";

const MyReviews = () => {
    const [chefReviews, setChefReviews] = React.useState();
    const [avgRating, setAvgRating] = React.useState();
    const [perRating, setPerRating] = React.useState()
    const fetchAllReviews = async () => {
        const response = await axiosInstance().post(AllChefReviews, {})
        setChefReviews(response?.data?.data)
        const rating = Number(response?.data?.data?.chefResult[0]?.avg.toFixed(1))
        setAvgRating(rating)
        const ratinPer = response?.data?.data?.ratingPer
        setPerRating(ratinPer)
    }
    useEffect(() => {
        fetchAllReviews();
    }, []);

    return (
        <>
            <div>
                <div className="dash-R">
                    <div className="dash-head">
                        <h3>my reviews</h3>
                    </div>
                    <div className="dash-bottom mt-2 my-orders">
                        <div className="my-orders-head d-flex align-items-center justify-content-between">
                            <ul className="nav nav-tabs my-orders-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="tab-1" data-bs-toggle="tab" data-bs-target="#new-order" type="button" role="tab" aria-controls="new-order" aria-selected="true">Chef Reviews</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <NavLink
                                        to="/dashboard/dishreviews"
                                        className="nav-link"
                                    >
                                        Dish Reviews
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="my-orders-body">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="new-order" role="tabpanel" aria-labelledby="tab-1">
                                    <h3 className="my-orders-title">overall ratings</h3>
                                    <div className="overall-ratings-box">
                                        <h4 className="total-rating">{Number(avgRating) > 0 ? Number(avgRating).toFixed(1) : "0.0"}</h4>
                                        <ul className="rating-star">
                                            {avgRating !== undefined ?
                                                <li><ReactStars size={24} count={5} activeColor="#8d126e" value={avgRating} isHalf={true} edit={false} /></li>
                                                : ""}
                                        </ul>
                                        <div className='rating-box-bg'>
                                            <ul>
                                                {perRating !== undefined ?
                                                    <>
                                                        <li><span>5 star</span><ProgressBar now={perRating[0]} /><span>{perRating[0]}%</span></li>
                                                        <li><span>4 star</span><ProgressBar now={perRating[1]} /><span>{perRating[1]}%</span></li>
                                                        <li><span>3 star</span><ProgressBar now={perRating[2]} /><span>{perRating[2]}%</span></li>
                                                        <li><span>2 star</span><ProgressBar now={perRating[3]} /><span>{perRating[3]}%</span></li>
                                                        <li><span>1 star</span><ProgressBar now={perRating[4]} /><span>{perRating[4]}%</span></li>
                                                    </>
                                                    : ""}
                                            </ul>
                                        </div>
                                    </div>
                                    <hr className="grey-line" />
                                    <div className="row chef-reviews">
                                        <div className="col-md-12">
                                            <h3 className="my-orders-title">customer reviews</h3>
                                        </div>
                                        {chefReviews?.reviewResult !== undefined &&
                                            chefReviews?.reviewResult.map((data, index) => {
                                                return (
                                                    <div className="col-md-6 chef-reviews-box" key={index}>
                                                        <small>{moment(data?.createdAt).format("MM/DD/YYYY")}</small>
                                                        <h3>{data?.reviewBy?.fullName}</h3>
                                                        <ul className="rating-star pb-1">
                                                            <li><ReactStars size={24} count={5} activeColor="#8d126e" value={data?.chefRating} isHalf={true} edit={false} /></li>
                                                        </ul>
                                                        <p>{data?.chefReview}</p>
                                                    </div>
                                                )
                                            })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyReviews