import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation, useNavigate, NavLink } from "react-router-dom";
import InnerHeader from "./InnerHeader";
import SideBar from "./SideBar";
import { ViewDishURL, ExtensionChangeURL } from "../../component/config/config";
import { EditDishURL } from "../../component/config/config";
import { Form, useFormik, FormikProvider, Field, ErrorMessage } from "formik";
import authHeader from "../services/auth_header";
import { Avatar, Dialog } from "@material-ui/core";
import { ImageURL } from "../../component/config/config";
import { FoodCategoryURL } from "../../component/config/config";
import { CuisinesURL } from "../../component/config/config";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tostS, tostE } from "../../toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../config/axios";
import { useSelector } from "react-redux";
import { convertStringToPascalCase, handleFormattedInputChange } from "../../utility/convertStringToPascalCase";

const EditDish = () => {
  const [formValues, setFormValues] = useState([]);
  const navigate = useNavigate();
  const grip = <FontAwesomeIcon icon={faGripLines} />;
  const commission = useSelector((state) => state.commissionReducer);

  const chefCommission = commission?.commission?.commission
    ? commission?.commission?.commission
    : commission?.commission?.globalCommission;

  const [tagsData, setTagsData] = useState([
    {
      title: "Dairy Free",
      status: false,
    },
    {
      title: "Gluten Free",
      status: false,
    },
    {
      title: "Nut Free",
      status: false,
    },
    {
      title: "Keto-Friendly",
      status: false,
    },
    {
      title: "Low Carb",
      status: false,
    },
    {
      title: "Organic",
      status: false,
    },
    {
      title: "Vegan",
      status: false,
    },
    {
      title: "Vegetarian",
      status: false,
    },
    {
      title: "Halal",
      status: false,
    },
    {
      title: "Kosher",
      status: false,
    },
  ]);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const [cuisineType, setCuisineType] = useState();
  const [foodType, setFoodType] = useState();
  const [image, setImage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [start, setStart] = useState(false);
  const [active, setActive] = useState(false);
  const [cloneEdit, setCloneEdit] = useState(false);
  const [checkValue, setCheckValue] = useState({});
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drag = (e) => {
    const copyItems = [...formValues];
    const dragItemContent = copyItems[dragItem.current];
    copyItems.splice(dragItem.current, 1);
    copyItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setFormValues(copyItems);
  };
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i] = e.target.value;
    setFormValues(newFormValues);
    setFieldValue("ingredients", newFormValues);
  };
  const handleChangeTags = (e, i, data) => {
    const newTagsData = tagsData.map((tag, index) =>
      index === i ? { ...tag, status: e.target.checked } : tag
    );

    setTagsData(newTagsData);

    const restrictedDiets = formik.values.restrictedDiets.slice(); // Copy the array
    if (e.target.checked) {
      restrictedDiets.push(data.title);
    } else {
      const index = restrictedDiets.indexOf(data.title);
      if (index !== -1) {
        restrictedDiets.splice(index, 1); // Remove the tag if it exists
      }
    }

    setFieldValue("restrictedDiets", restrictedDiets);
  };
  const inputRef = useRef(null);

  let addFormFields = () => {
    setFormValues([...formValues, ""]);
    setFieldValue("ingredients", [...formValues, ""]);
    inputRef.current?.focus();
  };
  //For remove a ingredients field
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setFieldValue("ingredients", newFormValues);
  };
  const { id } = useParams();
  const { state } = useLocation();
  const [editInfo, setEditInfo] = useState({});

  const myValidationSchema = Yup.object().shape({
    name: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(3, " Dish Name must be at least 3 characters ")
      .max(55, " Dish Name must be less than 55 characters ")
      .required("Dish Name is  required")
      .test(
        "trim",
        "Dish Name is not valid",
        (val) =>
          val !== undefined && !val.match(/^[0-9]*$/, "Dish name is not valid")
      )
      .when("dishInMenu", {
        is: true,
        then: Yup.string().test(
          "nameRequiredIfNotClone",
          "Oops this dish is already added in the menu so you can't change its name to 'clone'",
          (val) => !val?.toLowerCase()?.includes("clone")
        ),
      }),
    cuisine: Yup.string().required("Cuisine is Required "),
    price: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(1, " Price must be at least 1 digits ")
      .max(8, " Price not more than 8 digits ")
      .required("Price is required ")
      .test(
        "is-decimal",
        "Price should be valid",
        (val) => val !== undefined && val.match(/^[0-9]+(\.[0-9]{1,2})?$/)
      )
      .test(
        "maxFourDigits",
        "Price should be more than 0 ",
        (number) => Number(number) > 0
      ),
    foodType: Yup.string().required("Food Type is required"),
    portionSize: Yup.string().required("Portion Size is required"),
    portionSizeCount: Yup.string()
    .nullable() // Allow null values
    .transform((value) => (value === null ? "0" : value))
    .when("portionSize", {
      is: "Count", // When portionSize is equal to "Count"
      then: Yup.string()
        .required("Portion Size Count is required") // Apply the required validation
        .test(
          "is-greater-than-zero", // Custom test name
          "Portion Size Count must be greater than 0", // Error message
          (value) => value && Number(value) > 0 // Custom validation logic
        ),
      otherwise: Yup.string().notRequired(), // In other cases, it's not required
    }),  
    preprationTime: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .required("Preparation Time is required")
      .matches(/^\d+$/, "Enter valid Preparation Time")

      .test(
        "maxTwoDigits",
        "Preparation Time should not be more than 120 minutes",
        (number) => Number(number) <= 120
      ),
    description: Yup.string()
      .min(5, "Dish Description must be at least 5 characters ")
      .max(750, "Dish Description cannot be more than 750 characters ")
      .required("Description is required "),
    ingredients: Yup.array()
      .min(1, "Ingredient(s) is/are required")
      .of(
        Yup.string()
          .trim()
          .max(30, " Ingredient(s) name must not be more than 30 characters ")
          .required("Ingredient is required ")
      ),
    // dishImage: Yup.mixed()
    //     .test(
    //         "fileSize",
    //         "File too large",
    //         (value) => !value || (value && value.size <= 5000000)
    //     )
    //     .test(
    //         "fileFormat",
    //         "Only accept JPG,JPEG,PNG",
    //         (value) =>
    //             !value || (value && SUPPORTED_FORMATS.includes(value.type))
    //     ),
  });
  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: true,
    initialValues: {
      id: "",
      price: "",
      cuisine: "",
      name: "",
      foodType: "",
      portionSize: "",
      description: "",
      ingredients: [],
      restrictedDiets: "",
      preprationTime: "",
      dishImage: "",
      dishInMenu: true,
      portionSizeCount: "",
    },
    validationSchema: myValidationSchema,
    onSubmit: (values) => {
      if (
        values?.name?.length > 0 &&
        values?.name?.toLowerCase().includes("clone")
      ) {
        handleClone(values);
      } else {
        dishSubmit(values);
      }
    },
  });
  const dishSubmit = async (values) => {
    let tags = [];
    tagsData.map((element) => {
      if (element.status) {
        tags.push(element.title);
      }
    });
    let unique = [];
    for (let i = 0; i < formValues.length; i++) {
      if (unique.indexOf(formValues[i]) === -1) {
        unique.push(formValues[i]);
      }
    }
    try {
      let formData = new FormData();
      formData.append("id", values.id);
      formData.append("price", values?.price);
      formData.append("cuisine", values?.cuisine);
      formData.append("name", convertStringToPascalCase(values?.name?.trim()));
      formData.append("foodType", values?.foodType);
      formData.append("portionSize", values?.portionSize);
      formData.append("portionSizeCount", values.portionSize === "Count" ? values.portionSizeCount : 0);
      formData.append("imagePath", "chef/" + chefID);
      formData.append("description", values?.description.replace(/ +/g, " "));
      formData.append("ingredients", JSON.stringify(unique));
      formData.append("restrictedDiets", JSON.stringify(tags));
      formData.append("preprationTime", values?.preprationTime);
      formData.append("dishImage", values?.dishImage);
      formData.append("oldDishImage", image == "" ? "" : values?.dishImage);

      const response = await axiosInstance().post(EditDishURL, formData);
      const data = response.data.data;
      if (response.status === 200) {
        tostS(response.data.message);
        formik.resetForm();
        navigate("/dashboard/mymenu/all");
      }
    } catch (error) {
      setIsDisabled(false);
      tostE(error.response.data.message);
    }
  };

  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );
  const chefID = LoginData?.chefDetails?.chefID;

  let {
    submitCount,
    errors,
    touched,
    setFieldValue,
    getFieldProps,
    handleSubmit,
    values,
    setValues,
  } = formik;
  const fetchData = async () => {
    document.body.classList.add("loading-indicator");
    const foodresponse = await axios.get(FoodCategoryURL);
    const cuisineresponse = await axios.get(CuisinesURL);
    setFoodType(foodresponse?.data?.data);
    setCuisineType(cuisineresponse?.data?.data);
    try {
      const response = await axiosInstance().post(ViewDishURL, { id: id });
      // document.body.classList.remove('loading-indicator')
      const message = response.data.data;
      setEditInfo({ ...message });
      const newFieldValues = {
        id: message._id,
        name: message.name,
        cuisine: message.cuisine,
        price: message.price.toString(),
        foodType: message.category,
        portionSize: message.portionSize,
        portionSizeCount: message.portionSizeCount,
        preprationTime: message.preprationTime.toString(),
        description: message.description,
        ingredients: message.ingredientResult.map((data) => data.name),
        restrictedDiets: message.restrictedDiets,
        dishImage: message.dishImage,
        dishInMenu: message.dishInMenu,
      };
      setCheckValue(newFieldValues);
      setFieldValue("id", message._id);
      setFieldValue("name", message.name);
      setFieldValue("cuisine", message.cuisine);
      setFieldValue("price", message.price.toString());
      setFieldValue("foodType", message.category);
      setFieldValue("portionSize", message.portionSize);
      setFieldValue("portionSizeCount", message.portionSizeCount);
      setFieldValue("preprationTime", message.preprationTime.toString());
      setFieldValue("description", message.description);
      setFieldValue(
        "ingredients",
        message.ingredientResult.map((Daa) => Daa.name)
      );
      setFieldValue("restrictedDiets", message.restrictedDiets);
      setFieldValue("dishImage", message.dishImage);
      setFieldValue("dishInMenu", message.dishInMenu);

      let tagArray = tagsData.map((dataaa) => {
        if (message.restrictedDiets.includes(dataaa.title)) {
          return {
            title: dataaa.title,
            status: true,
          };
        } else {
          return dataaa;
        }
      });
      setTagsData([...tagArray]);
      const fvArray = message.ingredientResult.map((Daa) => Daa.name);
      setFormValues([...fvArray]);
      document.body.classList.remove("loading-indicator");
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      const message1 = error.response.data.message;
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const priceChange = () => {
    let Z = chefCommission / 100;

    let Y = formik.values.price * Z;
    let X = formik.values.price - Y;
    return X ? X.toFixed(2) : 0;
  };
  const handleTime = (e) => {
    let z = e.target.value;
    let zipReg = /^\d+$/;
    if (zipReg.test(z) || z === "") {
      if (z.length <= 3) {
        setFieldValue("preprationTime", z);
      }
    }
  };

  const handlePortionSizeCount = (sizeCount) => {
    let z=sizeCount
    let zipReg = /^\d+$/;
    if (zipReg.test(z) || z === "") {
      if (z.length <= 3) {
        setFieldValue("portionSizeCount", sizeCount);
      }
    }
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      // const form = event.target.form;
      // const index = Array.prototype.indexOf.call(form, event.target);
      // form.elements[index + 1].focus();
      event.preventDefault();
      addFormFields();
    }
  }
  const prof = async (e) => {
    document.body.classList.add("loading-indicator");

    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      if (parseInt(e.target.files[0].size) <= 5145728) {
        document.body.classList.remove("loading-indicator");
        setFieldValue("dishImage", e.target.files[0]);
        setImage(URL.createObjectURL(e.target.files[0]));
      } else {
        tostE("Please upload a file smaller than 5 MB");
      }
    } else if (
      e.target.files[0].type === "image/heif" ||
      e.target.files[0].type === "image/heic" ||
      e.target.files[0].type === ""
    ) {
      let formData = new FormData();
      formData.append("imagePath", "chef/" + chefID);
      formData.append("extension", e.target.files[0]);

      const resp = await axios.post(ExtensionChangeURL, formData);
      document.body.classList.remove("loading-indicator");
      setFieldValue("dishImage", resp.data.data[0]);
      setImage(ImageURL + resp.data.data[0]);
    } else {
      document.body.classList.remove("loading-indicator");
      tostE("Please upload a file with png,jpg,heic,heif or jpeg format.");
    }
  };
  const handleClone = (data) => {
    setCloneEdit({ ...cloneEdit, data: data, show: true });
  };
  const handleCloneClose = () => {
    setCloneEdit({ ...cloneEdit, id: null, show: false });
  };

  function isEqual(obj1, obj2) {
    const areArraysEqual = (arr1, arr2) =>
      // arr1.length === arr2.length &&
      // arr1.every((el) => arr2.some((item) => item.includes(el.trim())));

      arr1.length === arr2.length &&
      arr1.every((el, index) => el === arr2[index]);

    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return obj1 === obj2;
    }

    const keys1 = obj1 && Object?.keys(obj1);
    const keys2 = obj2 && Object?.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      let val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 == "string") {
        val1 = val1.trim();
      }
      if (Array.isArray(val1) && Array.isArray(val2)) {
        if (!areArraysEqual(val1, val2)) {
          return false;
        }
      } else if (typeof val1 === "object" && typeof val2 === "object") {
        if (!isEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  }
  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>my menu</h3>
          <nav className="breadcrumb-box">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard/mymenu/all">My Menu</NavLink>
              </li>
              <li className="breadcrumb-item">
                <a href="#">All </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Dish
              </li>
            </ol>
          </nav>
        </div>
        <div className="dash-bottom mt-2 edit-dish-wrapper">
          <div className="form-box row">
            <div className="col-md-9 m-auto">
              {Object.keys(editInfo).length ? (
                <FormikProvider value={formik}>
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <div className="upload-photo">
                        <div className="avatar-upload">
                          <span className="info-tag tool-tip-box con-tooltip top">
                            <b>*</b>
                            <i className="ri-information-line"></i>
                            <div className="tooltip ">
                              <p>
                                Upload a mouth-watering picture of your dish for
                                the customers to see when they search for it.
                                Please refer to the instructions on how to take
                                good food pictures in the FAQ section.
                              </p>
                            </div>
                          </span>
                          <div className="avatar-edit">
                            <input
                              type="file"
                              name="dishImage"
                              id="imageUpload"
                              onChange={(e) => prof(e)}
                              accept="image/png, image/jpeg,image/jpg,image/heif,image/heic"
                            />
                            <label
                              for="imageUpload"
                              className="img-upload-icon"
                            >
                              <i className="ri-pencil-line"></i>
                            </label>
                          </div>
                          <Avatar
                            className="avatar-preview"
                            src={
                              image != "" ? image : ImageURL + values.dishImage
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="combine-group d-flex ">
                      <div className="mb-3">
                        <label className="form-label">Dish Name *</label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Dish Name"
                          autoFocus={true}
                          value={values?.name}
                          onChange={(e) =>
                            handleFormattedInputChange({
                              event: e,
                              fieldName: "name",
                              formatterFunction: convertStringToPascalCase,
                              setFieldValue,
                            })
                          }
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.name
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        ></Field>
                        <span className="text-danger">
                          <ErrorMessage name="name" />
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Cuisine *</label>
                        <Field
                          as="select"
                          name="cuisine"
                          required
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.cuisine && touched?.cuisine
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          value={values.cuisine}
                        >
                          <option value="">Select</option>
                          {cuisineType !== undefined &&
                            cuisineType?.map((cuisine) => {
                              return (
                                <option value={cuisine?._id}>
                                  {cuisine?.name}
                                </option>
                              );
                            })}
                        </Field>
                        <span className="text-danger">
                          <ErrorMessage name="cuisine" />
                        </span>
                      </div>
                    </div>
                    <div className="combine-group d-flex ">
                      <div className="mb-3">
                        <label className="form-label">Price *</label>
                        <Field
                          type="text"
                          name="price"
                          value={`$${values?.price.replace(/ +/g, " ")}`}
                          placeholder="$0.00"
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.price && touched?.price
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          onChange={(e) => {
                            let t = e.target.value;

                            t = t.slice(1);
                            let cond =
                              (t.charAt(t.length - 1) === "." &&
                                t.charAt(t.length - 2) === ".") ||
                              t.split(".").length > 2;
                            let zipReg = /[0-9.]+$/;
                            if ((zipReg.test(t) || t === "") && !cond) {
                              setFieldValue(
                                "price",
                                t.indexOf(".") >= 0
                                  ? t.substr(0, t.indexOf(".")) +
                                      t.substr(t.indexOf("."), 3)
                                  : t
                              );
                            }
                          }}
                        />
                        <span className="text-danger">
                          <ErrorMessage name="price" />
                        </span>
                        <small className="Price-suggestion">
                          <b>
                            You earn <i>${priceChange()}</i> from each dish
                            sold!
                          </b>
                          Wanna Eat collects $
                          {(formik.values.price * chefCommission) / 100
                            ? (
                                (formik.values.price * chefCommission) /
                                100
                              ).toFixed(2)
                            : 0}{" "}
                          ({chefCommission}%) to cover marketing, operations,
                          customer support and software development costs.
                        </small>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Food Type * </label>
                        <Field
                          as="select"
                          name="foodType"
                          required
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.foodType && touched?.foodType
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          value={values.foodType}
                        >
                          <option value="">Select</option>
                          {foodType !== undefined &&
                            foodType?.map((food) => {
                              return (
                                <option value={food?._id}>{food?.name}</option>
                              );
                            })}
                        </Field>
                        <span className="text-danger">
                          <ErrorMessage name="foodType" />
                        </span>
                      </div>
                    </div>
                    <div className="combine-group d-flex ">
                      <div className="mb-3">
                        <label className="form-label">
                          Portion Size *
                          <span className="tool-tip-box con-tooltip top">
                            <i className="ri-information-line"></i>
                            <div className="tooltip ">
                              <p>
                                Select the size of your dish/container for one
                                serving, so that the customers know what to
                                expect. Additionally, you can elaborate the
                                count of items in your dish or number of people
                                the dish serves, etc using the ‘Dish
                                Description’ field.
                              </p>
                            </div>
                          </span>
                        </label>
                        <Field
                          as="select"
                          name="portionSize"
                          required
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.portionSize && touched?.portionSize
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          value={values.portionSize}
                        >
                          <option value="">Select</option>
                          <option value="4oz">4 oz</option>
                          <option value="8oz">8 oz</option>
                          <option value="12oz">12 oz</option>
                          <option value="16oz">16 oz</option>
                          <option value="24oz">24 oz</option>
                          <option value="32oz">32 oz</option>
                          <option value="Catering Half Tray">
                            Catering Half Tray
                          </option>
                          <option value="Catering Full Tray">
                            Catering Full Tray
                          </option>
                          <option value="Count">
                            Count
                          </option>
                        </Field>
                        <span className="text-danger">
                          <ErrorMessage name="portionSize" />
                        </span>
                      </div>                      
                      {values?.portionSize === "Count" && (
                        <div className="mb-3">
                           <label className="form-label">
                        Portion Size Count *
                        <span className="tool-tip-box con-tooltip top">
                          <i className="ri-information-line"></i>
                          <div className="tooltip ">
                            <p>
                            Enter number of items in one serving.
                            </p>
                          </div>
                        </span>
                      </label>
                         <Field
                          type="text"
                          name="portionSizeCount"
                          placeholder=""
                          required
                          value={values.portionSizeCount}
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.portionSizeCount && touched?.portionSizeCount
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          onChange={(e) => handlePortionSizeCount(e.target.value)}
                        />
                       <span className="text-danger">
                          {formik.errors?.portionSizeCount && (
                            <div text-danger>
                              {formik.errors?.portionSizeCount}
                            </div>
                          )}
                        </span>
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">
                          Preparation Time (in mins) *
                          <span className="tool-tip-box con-tooltip top">
                            <i className="ri-information-line"></i>
                            <div className="tooltip ">
                              <p>
                                How much time will it take for you to prepare
                                this dish?
                              </p>
                            </div>
                          </span>
                        </label>
                        <Field
                          type="text"
                          name="preprationTime"
                          value={values?.preprationTime.replace(/ +/g, " ")}
                          placeholder="10"
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.preprationTime &&
                                touched?.preprationTime
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          onChange={(e) => handleTime(e)}
                        />
                        <span className="text-danger">
                          <ErrorMessage name="preprationTime" />
                        </span>
                      </div>
                    <div className="mb-3 textarea-box">
                      <label className="form-label">
                        Dish Description *
                        <span className="tool-tip-box con-tooltip top">
                          <i className="ri-information-line"></i>
                          <div className="tooltip ">
                            <p>
                              This is your opportunity to describe your dish and
                              tell us what makes it special. So, make it count!
                            </p>
                          </div>
                        </span>
                      </label>
                      <Field
                        component="textarea"
                        rows="4"
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.description && touched?.description
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        name="description"
                        value={formik.values.description}
                        placeholder="Please describe this dish. What flavors or spices does it include? What makes it special? ex. A traditional pasta of Genoa; this classic Italian dish consists of penne topped with a sauce made up of olive oil, fresh basil, and buttery Pecorino cheese."
                      ></Field>
                      <span className="text-danger">
                        <ErrorMessage name="description" />
                      </span>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Ingredients *</label>
                      <small className="inner-text-content">
                        Please arrange the ingredients in descending order i.e.
                        the most used ingredient should be at the top of the
                        list and least used ingredient at the bottom
                      </small>
                    </div>
                    {formValues.map((element, index) => (
                      <div
                        className={
                          start
                            ? "ingredients-box mb-3 active"
                            : "ingredients-box mb-3"
                        }
                      >
                        <div
                          key={index}
                          className="d-flex ingredients-detail mb-3"
                          onDragStart={(e) => {
                            setStart(true);
                            setActive(true);
                            dragStart(e, index);
                          }}
                          onDragEnter={(e) => {
                            setStart(false);
                            dragEnter(e, index);
                          }}
                          onDragEnd={drag}
                          draggable
                        >
                          <input
                            type="text"
                            name="ingredientResult"
                            value={element?.replace(/ +/g, " ")}
                            placeholder="Name"
                            onChange={(e) => handleChange(index, e)}
                            onKeyDown={handleEnter}
                            required
                            className={`form-control ${
                              submitCount > 0
                                ? errors?.ingredientResult &&
                                  touched?.ingredientResult
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            ref={inputRef}
                          />
                          {/* {element.length > 30 ?} */}

                          <div className="action-btn d-flex">
                            <a>{grip}</a>
                            {index >= 0 ? (
                              <a>
                                {" "}
                                <i
                                  className="ri-subtract-line"
                                  onClick={() => removeFormFields(index)}
                                ></i>
                              </a>
                            ) : null}
                          </div>
                        </div>
                        {element.length <= 0
                          ? tostE("Ingredient is required ")
                          : ""}

                        {element.length > 30
                          ? tostE(
                              "Ingredient(s) name must not be more than 30 characters"
                            )
                          : ""}
                      </div>
                    ))}
                    {formValues.length === 0 && (
                      <span className="text-danger">
                        Ingredient(s) is/are required
                      </span>
                    )}
                    <a
                      className="btn purple-btn trans-btn min-height mb-3 s-btn"
                      // className={formValues.length != 0 ? "btn purple-btn trans-btn min-height mb-3 s-btn" : "btn red-btn trans-btn min-height mb-3 s-btn"}
                      onClick={() => addFormFields()}
                    >
                      {" "}
                      Add ingredient
                    </a>
                    <div
                      className="mb-3 textarea-box"
                      name="restrictedDiets"
                      // value={values?.restrictedDiets}
                      onChange={(e) => {
                        // setFieldValue("restrictedDiets", e.target.value);
                      }}
                    >
                      <label className="form-label">Dietary Tags </label>
                      <ul key=" ul" className="dietary-list">
                        {tagsData.map((data, index) => {
                          return (
                            <li>
                              <label className="coustom-checkbox">
                                <input
                                  checked={data.status}
                                  value={data.status}
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleChangeTags(e, index, data)
                                  }
                                />
                                <span className="checkmark"></span>
                                <p>{data.title}</p>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    <div className="mb-3 text-center mt-5">
                      <button
                        type="submit"
                        className={
                          !(formik.dirty && formik.isValid) ||
                          isEqual(formik.values, checkValue)
                            ? "btn btn-secondary min-height"
                            : "btn purple-btn min-height hvr-sweep-to-right"
                        }
                        disabled={
                          !(formik.dirty && formik.isValid) ||
                          isEqual(formik.values, checkValue)
                        }
                      >
                        Update
                      </button>
                    </div>
                  </Form>
                </FormikProvider>
              ) : null}
            </div>
          </div>
        </div>
        {/* <div className="screen-div-info">
                    <ul className="info-list">
                        <li><a href="tel:9513539491"><i className="ri-phone-line"></i>951-353-9491</a></li>
                        <li><a href="mailto:info@email.com"><i className="ri-mail-line"></i>info@email.com</a></li>
                    </ul>
                </div> */}
        <Dialog open={cloneEdit.show}>
          <div className="common-modal space-2">
            <div className="modal-dialog">
              <div className="modal-content">
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleCloneClose()}
                >
                  <i className="ri-close-circle-line"></i>
                </button>
                <div className="modal-head text-center mb-4">
                  <h4 className="modal-title">Dish Name</h4>
                  <p>
                    Your dish name still states ‘Clone’. Would you like to
                    rename it now?
                  </p>
                </div>
                <div className="modal-body text-center">
                  <div className="mt-3 yes-no-btn">
                    <button
                      className="btn purple-btn min-height trans-btn hvr-sweep-to-right me-1"
                      onClick={() => dishSubmit(cloneEdit?.data)}
                    >
                      Later
                    </button>
                    <button
                      className="btn purple-btn min-height hvr-sweep-to-right ms-1"
                      onClick={() => handleCloneClose()}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default EditDish;
