import React, { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom";
import { ChefFAQ } from '../../component/config/config';
import axios from 'axios';
import authHeader from "../services/auth_header";
import { Typography } from "@material-ui/core";
import { tostE } from '../../toast';
import axiosInstance from "../config/axios";

const Cheffaq = () => {
    const [allFAQ, setAllFAQ] = React.useState();
    const fetchFAQ = async () => {
        document.body.classList.add('loading-indicator');
        try {
            const response = await axiosInstance().post(ChefFAQ, {})
            document.body.classList.remove('loading-indicator')
            setAllFAQ(response.data.data)
        }
        catch (error) {
            document.body.classList.remove('loading-indicator')
            tostE(error.response.data.message)
        }
    }
    useEffect(() => {
        fetchFAQ();
    }, []);
    return (
        <>
            <div className="dash-R">
                <div className="dash-head d-flex justify-content-between">
                    <h3>HELP Center</h3>
                    <NavLink
                        to="/dashboard/chefcontactus"
                        className="accpet-btn btn purple-btn min-height hvr-sweep-to-right"
                    >
                        contact us
                    </NavLink>
                </div>

                <div className="dash-bottom faq-inner faq-inner-purple  mt-2 my-order">
                    <h3 className="my-orders-title mb-3">Frequently Asked Questions</h3>
                    {allFAQ !== undefined && allFAQ?.map((data, index) => {
                        return (
                            <div className="accordion" id="accordionExample" key={index}>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={"headingOne" + index}>
                                        <button className="accordion-button collapsed" style={{ wordBreak: "break-all" }} type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded="true" aria-controls={"collapseOne" + index}>
                                            {data.question}
                                        </button>
                                    </h2>
                                    <div id={"collapseOne" + index} className="accordion-collapse collapse " aria-labelledby={"headingOne" + index} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                <Typography style={{ textAlign: "left", fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: data?.answer }} variant="h6" ></Typography>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>);
}

export default Cheffaq
