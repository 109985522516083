import axios from "axios";
import authHeader from "../services/auth_header";
import { BASE } from "./config";

export default (history = null) => {
  const baseURL = BASE + "/api/chef/private";
  let headers = {};
  if (authHeader()) {
    headers.Authorization = authHeader().authorization;
  }
  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      console.log(error.response);
      if (error.response.status === 404) {
        console.log("here", error);
        localStorage.clear();

        // navigate("/");

        if (history) {
          history.push("/");
        } else {
          window.location = "/";
        }
      }
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );

  return axiosInstance;
};
