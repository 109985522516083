import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ChefContactUs } from "../../component/config/config";
import axios from "axios";
import authHeader from "../services/auth_header";
import { toast } from "react-toastify";
import { tostS, tostE } from "../../toast";
import axiosInstance from "../config/axios";

const ContactUsHelp = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const body = {
      subject: values?.subject,
      comment: values?.comment,
    };
    setIsDisabled(true);

    const response = await axiosInstance().post(ChefContactUs, body);
    if (response.status === 200) {
      tostS(response.data.message);
      navigate("/dashboard/cheffaq");
    } else {
      setIsDisabled(false);

      tostE(response.data.message);
    }
  };
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    subject: "",
    comment: "",
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(3, "Subject must be at least 3 characters")
      .max(150, "Subject must not be more than 150 characters ")
      .required(" Subject is required"),
    comment: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(3, "Comment must be at least 3 characters")
      .max(1000, "Comment must not be more than 1000 characters ")
      .required("Comment is required"),
  });

  return (
    <>
      <div class="dash-R">
        <div class="dash-head">
          <h3>HELP Center</h3>
          <nav class="breadcrumb-box">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="cheffaq">Help Center</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {" "}
                Contact Us
              </li>
            </ol>
          </nav>
          <p>
            We’ve got you covered. Please feel free to send us any queries you
            might have. Rest assured that we will get back to you via email with
            a response/solution within the next 24-48 hours.
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            let { submitCount, errors, touched, setFieldValue } = formik;
            return (
              <>
                <Form>
                  <div class="dash-bottom mt-2 help-center ">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="mb-3">
                          <label class="form-label">
                            Subject
                            <span className="text-danger"> *</span>
                          </label>
                          <Field
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            className={`form-control ${
                              submitCount > 0
                                ? errors?.subject && touched?.subject
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={formik.values.subject.replace(/ +/g, " ")}
                          ></Field>
                          <span className="text-danger">
                            <ErrorMessage name="subject" />
                          </span>
                        </div>
                        <div class="mb-3 textarea-box">
                          <label class="form-label">
                            Comment
                            <span className="text-danger"> *</span>
                          </label>
                          <Field
                            type="text"
                            component="textarea"
                            rows="4"
                            value={formik.values.comment.replace(/ +/g, " ")}
                            name="comment"
                            placeholder="Comment"
                            onChange={(e) => {
                              setFieldValue("comment", e.target.value);
                            }}
                            className={`form-control ${
                              submitCount > 0
                                ? errors?.comment && touched?.comment
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          ></Field>
                          <span className="text-danger">
                            <ErrorMessage name="comment" />
                          </span>
                        </div>
                        <div class=" mt-4 pt-2">
                          <button
                            type="submit"
                            disabled={isDisabled}
                            class="min-height btn purple-btn hvr-sweep-to-right"
                          >
                            submit
                          </button>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="help-center-content text-center">
                          <span>
                            <i class="ri-phone-line"></i>
                          </span>
                          <h4>support phone number</h4>
                          <a href="tel:8042015042">804-201-5042</a>
                          <small>Mon - Fri : 9am to 6pm EST</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
      {/* <div class="screen-div-info">
                <ul class="info-list">
                    <li><a href="tel:9513539491"><i class="ri-phone-line"></i>951-353-9491</a></li>
                    <li><a href="mailto:info@email.com"><i class="ri-mail-line"></i>info@email.com</a></li>
                </ul>
            </div> */}
    </>
  );
};

export default ContactUsHelp;
