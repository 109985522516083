import React from "react";
import { useNavigate } from "react-router-dom";
import singleLogo from "../../assets/images/singlelogo.jpg.png";
import { useSelector } from "react-redux";
import { tostE, tostS } from "../../toast";
import FileIcon from "../../assets/images/file-icon.jpg";
import Commision from "../../assets/Commission";
import { WannaEatBase } from "../config/config";
const Settings = () => {
  const navigate = useNavigate();
  const uploadDoc = () => {
    // if (id === true) {
    //     tostS("You have already submitted your documents")
    // }
    // else {

    // }align-items-center
    navigate("/dashboard/upload");
  };
  const uploadPay = () => {
    navigate("/dashboard/payment");
  };
  const commission = useSelector((state) => state.commissionReducer);

  // Now, you can access commission data from the Redux store

  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );
  const SignupData = useSelector((state) =>
    state.Signup.data ? state.Signup.data : {}
  );
  const id =
    (LoginData.chefDetails && LoginData.chefDetails.documentsComplete) ||
    (SignupData.chefDetails && SignupData.chefDetails.documentsComplete);
  return (
    <>
      <div className="dash-R">
        <div className="dash-head d-flex justify-content-between">
          <h3>settings</h3>
        </div>
        <div className="dash-bottom mt-2">
          <div className="row">
            <div className="col-md-3 settings-Left">
              <div className="similar-shadow text-center profile-box">
                <figure>
                  <img src={singleLogo} />
                </figure>
                <h3>Wanna Eat</h3>
                <ul className="contact-info">
                  <li>
                    <i className="ri-mail-line"></i>{" "}
                    <a href="mailto:hi@wannaeat.com">hi@wannaeat.com</a>
                  </li>
                  <li>
                    <i className="ri-phone-line"></i>{" "}
                    <a href="tel:+1 804-201-5042">+1 804-201-5042</a>
                  </li>
                </ul>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p> */}
              </div>
              <div className=" d-flex justify-content-center">
                <a
                  target="_blank"
                  href={`${WannaEatBase}/cheft&c`}
                  className="similar-shadow  profile-box d-flex justify-content-between px-4 py-2 cheftc-box"
                >
                  <div className="m-0 ">Chef Terms & Conditions</div>
                </a>
              </div>
              {/* <div className="similar-shadow  profile-box">
                                <h4 className="mb-3 profile-title">NOTIFICATIONS</h4>
                                <div className="notifications-box">
                                    <div className="notifications-box-head">
                                        <span>&nbsp;</span>
                                        <div className="d-flex">
                                            <h5 className="notifications-inner">Email</h5>
                                            <h5 className="notifications-inner">SMS</h5>
                                        </div>
                                    </div>
                                    <ul className="notifications-list">
                                        <li>
                                            <span>Onboarding</span>
                                            <div className="switch-toggle">
                                                <label className="switch">
                                                    <input type="checkbox" checked disabled />
                                                    <span className="slider round"></span>
                                                </label>
                                                <label className="switch">
                                                    <input type="checkbox" checked disabled />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Orders</span>
                                            <div className="switch-toggle">
                                                <label className="switch">
                                                    <input type="checkbox" checked disabled />
                                                    <span className="slider round"></span>
                                                </label>
                                                <label className="switch">
                                                    <input type="checkbox" checked disabled />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Delivery</span>
                                            <div className="switch-toggle">
                                                <label className="switch">
                                                    <input type="checkbox" checked disabled />
                                                    <span className="slider round"></span>
                                                </label>
                                                <label className="switch">
                                                    <input type="checkbox" checked disabled />
                                                    <span className="slider round" ></span>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Payments</span>
                                            <div className="switch-toggle">
                                                <label className="switch">
                                                    <input type="checkbox" checked disabled />
                                                    <span className="slider round" ></span>
                                                </label>
                                                <label className="switch">
                                                    <input type="checkbox" checked disabled />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span>Account Changes</span>
                                            <div className="switch-toggle">
                                                <label className="switch">
                                                    <input type="checkbox" checked disabled />
                                                    <span className="slider round"></span>
                                                </label>
                                                <label className="switch">
                                                    <input type="checkbox" checked disabled />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
            </div>
            <div className="col-md-9 setting-right">
              <div className="similar-shadow">
                <div className="tabs-similer upload-documents-btn-tabs">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="account-detail" data-bs-toggle="tab" data-bs-target="#tab-1" type="button" role="tab" aria-controls="home" aria-selected="true">Account Details</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="chef-details" data-bs-toggle="tab" data-bs-target="#tab-2" type="button" role="tab" aria-controls="profile" aria-selected="false">Chef Details</button>
                                        </li> */}
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="upload-documents"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-3"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => uploadDoc()}
                      >
                        Upload Documents
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="payment-infomations" data-bs-toggle="tab" data-bs-target="#tab-4" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={() => uploadPay()}>Payment Information</button>
                                        </li> */}
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    {/* <div className="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="account-detail">
                                            <form>
                                                <div className="combine-group d-flex">
                                                    <div className="mb-3">
                                                        <label className="form-label">First Name</label>
                                                        <input type="text" name="" placeholder="Arthur" className="form-control" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Last Name</label>
                                                        <input type="text" name="" placeholder="Keen" className="form-control" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email Address</label>
                                                        <div className="link-text-group">
                                                            <input type="text" name="" placeholder="ArthurSKeen@rhyta.com" className="form-control" />
                                                            <a type="button" data-bs-toggle="modal" data-bs-target="#phone-verification" className="verify-text" >Verify</a>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Mobile Number</label>
                                                        <div className="link-text-group">
                                                            <input type="text" name="" placeholder="507-450-8247" className="form-control" />
                                                            <a type="button" data-bs-toggle="modal" data-bs-target="#phone-verification" className="verify-text">Verify</a>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3"><button className="min-height btn purple-btn hvr-sweep-to-right">Update</button></div>
                                                </div>
                                            </form>
                                            <div className="mb-3 mt-5 profile-title">reset password</div>
                                            <form className="">
                                                <div className="combine-group d-flex">
                                                    <div className="mb-3">
                                                        <div className="combine-table d-flex justify-content-between"><label className="form-label">Current Password</label>
                                                            <a data-bs-toggle="modal" data-bs-target="#forget-password" className="forget-passowrd">Forgot Password</a>
                                                        </div>
                                                        <input type="text" name="" placeholder="*******" className="form-control" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">New Password</label>
                                                        <input type="text" name="" placeholder="*******" className="form-control" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Confirm New Password</label>
                                                        <input type="text" name="" placeholder="*******" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="mt-3"><button className="min-height btn purple-btn hvr-sweep-to-right">Update</button></div>
                                            </form>
                                        </div> */}
                    <div
                      className="tab-pane fade"
                      id="tab-2"
                      role="tabpanel"
                      aria-labelledby="upload-documents"
                    >
                      <form>
                        <div className="combine-group d-flex ">
                          <div className="mb-3">
                            <label className="form-label">Kitchen Name</label>
                            <input
                              type="text"
                              name=""
                              placeholder="Kitchen Name"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Chef Name</label>
                            <input
                              type="text"
                              name=""
                              placeholder="Michael Bruno"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Country of Origin
                            <span className="tool-tip-box con-tooltip top">
                              <i className="ri-information-line"></i>
                              <div className="tooltip ">
                                <p>Top</p>
                              </div>
                            </span>
                          </label>
                          <select className="form-control">
                            <option>India</option>
                          </select>
                        </div>
                        <div className="combine-group d-flex ">
                          <div className="mb-3">
                            <label className="form-label">Address line 1</label>
                            <input
                              type="text"
                              name=""
                              placeholder="Address"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Address line 2{" "}
                            </label>
                            <input
                              type="text"
                              name=""
                              placeholder="Address"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="combine-group d-flex ">
                          <div className="mb-3">
                            <label className="form-label">City</label>
                            <select className="form-control">
                              <option>City</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">State</label>
                            <select className="form-control">
                              <option>State</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label className="form-label"> Zip code</label>
                            <input
                              type="text"
                              name=""
                              placeholder="Zip code"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Chef ID</label>
                            <input
                              type="text"
                              name=""
                              placeholder="2442343"
                              className="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="mb-3 textarea-box">
                          <label className="form-label">
                            Description
                            <span className="tool-tip-box con-tooltip top">
                              <i className="ri-information-line"></i>
                              <div className="tooltip ">
                                <p>Top</p>
                              </div>
                            </span>
                          </label>
                          <textarea
                            className="form-control"
                            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Kitchen/Food Photos
                            <span className="tool-tip-box con-tooltip top">
                              <i className="ri-information-line"></i>
                              <div className="tooltip ">
                                <p>Top</p>
                              </div>
                            </span>
                          </label>
                          <div className="uploading-group">
                            <ul className="uploding-list">
                              <li>
                                <span>
                                  <img src="images/img-gallery-1.png" />
                                </span>
                                <small className="close-btn">
                                  <i className="ri-close-line"></i>
                                </small>
                              </li>
                              <li>
                                <span>
                                  <img src="images/img-gallery-2.png" />
                                </span>
                                <small className="close-btn">
                                  <i className="ri-close-line"></i>
                                </small>
                              </li>
                              <li>
                                <span>
                                  <img src="images/img-gallery-3.png" />
                                </span>
                                <small className="close-btn">
                                  <i className="ri-close-line"></i>
                                </small>
                              </li>
                              <li>
                                {" "}
                                <div className="file file--upload">
                                  <label
                                    for="input-file"
                                    className="file-label"
                                  >
                                    <i className="ri-add-circle-line"></i>{" "}
                                    <span>Add more</span>
                                  </label>
                                  <input id="input-file" type="file" />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Cuisines Offered *
                          </label>
                          <div className="form-group">
                            <input
                              type="text"
                              id="tag-input1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Delivery Preference
                            <span className="tool-tip-box con-tooltip top">
                              <i className="ri-information-line"></i>
                              <div className="tooltip ">
                                <p>Top</p>
                              </div>
                            </span>
                          </label>
                          <div className="radio-combine d-flex">
                            <label className="radio-coustom-design me-4">
                              <input
                                type="radio"
                                checked="checked"
                                name="radio"
                              />
                              <span className="checkmark"></span>
                              <p>Pick-up</p>
                            </label>
                            <label className="radio-coustom-design">
                              <input type="radio" name="radio" />
                              <span className="checkmark"></span>
                              <p>Delivery</p>
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 textarea-box">
                          <label className="form-label">
                            Instructions for customers / delivery driver pickup
                          </label>
                          <textarea
                            className="form-control"
                            placeholder="Instructions for customers/ delivery driver pickup"
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Tags</label>
                          <div className="form-group">
                            <input
                              type="text"
                              id="tag-input2"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3 text-left mt-5">
                          <button
                            type="button"
                            className="btn purple-btn min-height hvr-sweep-to-right"
                            data-bs-toggle="modal"
                            data-bs-target="#next-step"
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>

                    {/* <div className="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="payment-infomations"> */}
                    <div
                      className="uploading-group upload-document"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <ul
                        className="uploding-list"
                        style={{ padding: "10px 12px" }}
                      >
                        {id == true ? (
                          <li>
                            {/* <span> */}
                            <img
                              src={FileIcon}
                              style={{ width: "auto", height: "auto" }}
                            />
                            {/* <i className="ri-file-2-line"></i><b>abc.pdf</b> */}
                            {/* </span> */}
                            {/* <small className="close-btn"><i className="ri-close-line"></i></small> */}
                          </li>
                        ) : (
                          ""
                        )}
                        {/* <li><span><i className="ri-file-2-line"></i><b>abc.pdf</b></span><small className="close-btn"><i className="ri-close-line"></i></small></li>
                                                    <li><span><i className="ri-file-2-line"></i><b>abc.pdf</b></span><small className="close-btn"><i className="ri-close-line"></i></small></li>
                                                    <li><div className="file file--upload"> */}
                        {/* <label for="input-file" className="file-label">
                                                            <i className="ri-add-circle-line"></i> <span>Add more</span>
                                                        </label> */}
                        {/* <input id="input-file" type="file" /> */}
                        {/* </div></li> */}
                      </ul>
                    </div>

                    {/* <div className="mb-3 text-left mt-4 pt-2">
                                                <button type="button" className="btn purple-btn min-height hvr-sweep-to-right">Update</button>
                                            </div> */}
                    {/* </div> */}
                    <div
                      className="tab-pane fade"
                      id="tab-4"
                      role="tabpanel"
                      aria-labelledby="payment-infomations"
                    >
                      <form>
                        <div className="combine-group d-flex">
                          <div className="mb-3">
                            <label className="form-label">
                              Bank Account Holder Name
                            </label>
                            <input
                              type="text"
                              name=""
                              placeholder="Arthur Keen"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Bank Account Number
                            </label>
                            <input
                              type="text"
                              name=""
                              placeholder="645654656546"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Bank Routing Number
                          </label>
                          <input
                            type="text"
                            name=""
                            placeholder="dfdf53454"
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3 text-left mt-4 pt-2">
                          <button
                            type="button"
                            className="btn purple-btn min-height hvr-sweep-to-right"
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="screen-div-info">
                    <ul className="info-list">
                        <li><a href="tel:9513539491"><i className="ri-phone-line"></i>951-353-9491</a></li>
                        <li><a href="mailto:info@email.com"><i className="ri-mail-line"></i>info@email.com</a></li>
                    </ul>
                </div> */}
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade common-modal"
        id="phone-verification"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ri-close-circle-line"></i>
            </button>
            <div className="modal-head text-center mb-4">
              <figure>
                <img src="images/Confirmed-rafiki.png" />
              </figure>
              <h4 className="modal-title">phone verification</h4>
              <p>Please enter the OTP sent to your registered phone number</p>
            </div>
            <div className="modal-body text-center">
              <form>
                <div className="mb-3 verification-box d-flex">
                  <input
                    type="text"
                    name=""
                    placeholder="4"
                    className="form-control"
                  />
                  <input
                    type="text"
                    name=""
                    placeholder="2"
                    className="form-control"
                  />
                  <input
                    type="text"
                    name=""
                    placeholder="2"
                    className="form-control"
                  />
                  <input
                    type="text"
                    name=""
                    placeholder="4"
                    className="form-control"
                  />
                </div>
                <div className="mt-4 pt-3">
                  <button className="btn purple-btn min-height hvr-sweep-to-right">
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal fade common-modal"
        id="forget-password"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ri-close-circle-line"></i>
            </button>
            <div className="modal-head text-center mb-4">
              <figure>
                <img src="images/forget-password.png" />
              </figure>
              <h4 className="modal-title">forgot password</h4>
              <p>Please enter the OTP sent to your registered phone number</p>
            </div>
            <div className="modal-body text-center">
              <form>
                <div className="mb-3 verification-box d-flex">
                  <input
                    type="text"
                    name=""
                    placeholder="4"
                    className="form-control"
                  />
                  <input
                    type="text"
                    name=""
                    placeholder="2"
                    className="form-control"
                  />
                  <input
                    type="text"
                    name=""
                    placeholder="2"
                    className="form-control"
                  />
                  <input
                    type="text"
                    name=""
                    placeholder="4"
                    className="form-control"
                  />
                </div>
                <div className="mt-4 pt-3">
                  <button className="btn purple-btn min-height hvr-sweep-to-right">
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
