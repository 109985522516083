import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import moment from "moment-timezone";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ImageURL, UpdateMenuURL } from "../../component/config/config";
import axiosInstance from "../config/axios";
import { tostE, tostS } from "../../toast";
import { DishDetailsURL } from "../../component/config/config";
import { orderedDate } from "../../utility/DateFormat";

const UpdateMenu = () => {
  const DateAndTimeStyle={
    "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
      top: "-9px",
      fontSize:"12px"
    },
    ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":{
      fontSize:"12px"
    }
  }
  const location = useLocation();
  const navigate = useNavigate();
  const [showData, setShowData] = useState(location.state.data);
  const [changeDate, setChangeDate] = useState(
    ''
  );
  const [changeTime, setChangeTime] = useState(
    ''
  );
  const current_date = new Date();
  const { state } = useLocation();
  let { type } = useParams();
  let current_hours = current_date.getHours();
  let current_min = current_date.getMinutes();
  const [timeError, setTimeError] = useState(false);
  const [timeErrors, setTimeErrors] = useState([]);
  const [dateErrors, setDateErrors] = useState([]);
  const [dateError, setDateError] = useState(false);
  const [applySectionCheck, setApplySectionCheck] = useState(true);
  const [updateSectionCheck, setUpdateSectionCheck] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllCount,setSelectAllCount]=useState(0)

  const fetchData = async () => {
    let pastData = location.state.data;
    let dish = location.state.dishIds;
    if (location.state.menuDate) {
      axiosInstance()
        .post(DishDetailsURL, { dishIds: dish })
        .then((response) => {
          let arr = [];
          response.data.data.map((dish) => {
            let prevDish =
              pastData && pastData.filter((D) => D._id === dish._id);
            if (
              prevDish !== null &&
              prevDish !== undefined &&
              prevDish.length > 0
            ) {
              arr.push({ ...prevDish[0] });
              // setChangeDate(prevDish[0].orderByDate);
            // setChangeTime(prevDish[0].orderByTime);
            // setChangeDelivery(prevDish[0].deliveryOrPickupWindow);
            } else {
              arr.push({
                ...dish,
                totalServings: 0,
                orderByDate: "",
                orderByTime: "",
                deliveryOrPickupWindow: [
                  {
                    title: "10am-1pm",
                    status: false,
                  },
                  {
                    title: "4pm-7pm",
                    status: false,
                  },
                ],
              });
            }
          });
          setShowData(arr);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axiosInstance()
        .post(DishDetailsURL, { dishIds: state.data.map((da) => da._id) })
        .then((response) => {
          let arr = [];
          response.data.data.map((dish) => {
            let prevDish =
              pastData && pastData.filter((D) => D._id === dish._id);
            if (
              prevDish !== null &&
              prevDish !== undefined &&
              prevDish.length > 0
            ) {
              prevDish[0].deliveryOrPickupWindow = ['10am-1pm', '4pm-7pm'].map((window) => {
                const existingWindow = prevDish[0]?.deliveryOrPickupWindow?.find((w) => w === window);
                return {
                  title: window,
                  status: existingWindow ? true : false,
                };
              });
              arr.push({ ...prevDish[0] });
            } else {
              arr.push({
                ...dish,
                totalServings: 0,
                orderByDate: "",
                orderByTime: "",
                deliveryOrPickupWindow: [
                  {
                    title: "10am-1pm",
                    status: false,
                  },
                  {
                    title: "4pm-7pm",
                    status: false,
                  },
                ],
              });
            }
          });
          setShowData(arr);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    let tagArray = changeDelivery.map((dataaa) => {
      return dataaa;
    });
    setChangeDelivery([...tagArray]);
    fetchData();
  }, []);
  const handleupdate = (operation, index, e) => {
    let zipReg = /^\d+$/;
    let v = e.target.value;
    let arr = [...showData];
    if (operation === "add") {
      arr[index].totalServings++;
    }
    if (operation === "minus") {
      arr[index].totalServings--;
    }
    if ((operation === "type" && zipReg.test(v)) || v === "") {
      if (v.length <= 3) {
        arr[index].totalServings = v;
      }
    }
    setShowData(arr);
    handleUpdateChange()
  };

  const handleDate = (date) => {
    setChangeDate(date);
    handleApplySectionCheck(date, changeTime, selectedItems.length);
  };
  
  const handleTime = (time) => {
    setChangeTime(time);
    handleApplySectionCheck(changeDate, time, selectedItems.length);
  };
  
  const handleApplySectionCheck = (updatedDate, updatedTime, menuDetailsLength) => {
    const hasValidDelivery = (changeDelivery[0]?.status || changeDelivery[1]?.status);
    const isValid = hasValidDelivery && updatedDate && updatedTime && menuDetailsLength > 0;
    setApplySectionCheck(!isValid);
  };
  

  const handleChangeTags = (i, e, index) => {
    const a = [...changeDelivery];
    a[i].status = e.target.checked;
    setChangeDelivery([...a]);
    handleApplySectionCheck(changeDate,changeTime,selectedItems.length)

  };
  const handleChangeTags1 = (index, idx, e) => {
    let arr = [...showData];
    arr[index].deliveryOrPickupWindow[idx].status =
      !arr[index].deliveryOrPickupWindow[idx].status;
    setShowData(arr);
    handleUpdateChange()
  };
  const handleOrderByDateTags1 = (index, date) => {
    let arr = [...showData];
    arr[index].orderByDate = date;
    setShowData(arr);
    handleUpdateChange()
  };

  const handleOrderByTimeTags1 = (index, date) => {
    let arr = [...showData];
    arr[index].orderByDate = date;
    setShowData(arr);
    handleUpdateChange()
  };

  const [changeDelivery, setChangeDelivery] = useState([
    {
      title: "10am-1pm",
      status: false,
    },
    {
      title: "4pm-7pm",
      status: false,
    },
  ]);

  const submit = (e) => {
    e.preventDefault();
    let arr = [];
    arr = showData.map((dish) => {
      let windowData = [];
      dish.deliveryOrPickupWindow.map((element) => {
        if (element.status) {
          windowData.push(element.title);
        }
      });
      return {
        dishId: dish._id,
        totalServings: dish.totalServings,
        orderByDate:dish.orderByDate ? new Date(`${dish.orderByDate}`).toISOString():'Invalid Date',
        orderByTime: dish.orderByDate ? {
          hours: moment(dish.orderByDate).format("h"),
          minutes: moment(dish.orderByDate).format("mm"),
          format: moment(dish.orderByDate).format("a"),
        } : 'Invalid time',
        deliveryOrPickupWindow: windowData,
      };
    });

    let isValid = arr.map((_, i) => {
      const isDateAndTimeValid =
        arr[i]?.totalServings != 0 &&
        !arr[i]?.orderByDate.includes("Invalid Date") &&
        arr[i]?.orderByTime.hours !== "Invalid time" &&
        arr[i]?.orderByTime.minutes !== "Invalid time" &&
        arr[i]?.orderByTime.format !== "Invalid time"&&
        arr[i].deliveryOrPickupWindow.length > 0 

    
      const isWindowValid =
        arr[i]?.totalServings != 0 &&
        arr[i].deliveryOrPickupWindow.length > 0 &&
        changeDate !== "" &&
        changeTime !== "";    
        if (!isDateAndTimeValid && isWindowValid) {
          return false;
        }
      return isDateAndTimeValid || isWindowValid;
    });
    // let id = showData[0].menuId ? showData[0].menuId : showData[showData.length - 1].menuId
    let id = showData.filter((x) => x.menuId);
      if (isValid.every((_) => _) && timeErrors.filter(error => error === false || error === true).every((error) => error === false) && dateErrors.filter(error => error === false || error === true).every((error) => error === false)) {
      // try {
      axiosInstance()
        .post(UpdateMenuURL, {
          _id: localStorage.getItem("menuId")
            ? localStorage.getItem("menuId")
            : id[0].menuId,
          dishes: arr,
        })
        .then((response) => {
          if (response.status === 200) {
            navigate(`/dashboard/mymenu/${type}`);
            tostS(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          tostE(error?.response?.data?.message);
        });
    } else {
      if (timeErrors.filter(error => error === false || error === true).some((error) => error === true)) {
        tostE("Please select a valid time.");
      } else if (dateErrors.filter(error => error === false || error === true).some((error) => error === true)) {
        tostE("Please select a valid date.");
      } else {
        tostE("Please fill details");
      }
    }
  };
  const addDish = () => {
    // state.menuDate = moment(state.menuDate).format("DD-MMMM-YYYY")
    navigate(`/dashboard/mymenu/${moment(state.type||state.menuDate).format("DD-MMMM-YYYY")}`, {
      state: { data: showData, type: "update" },
      replace: true,
    });
    // console.log("1", state)
  };
  //   const one = new Date(type) - 1;
  const one = new Date(type);
  const handleApply = (data, index) => {
    setSelectedItems((prevSelectedItems) => {
      const itemId = data._id;
      const isSelected = prevSelectedItems.some((item) => item._id === itemId);
      const newSelectedItems = isSelected
        ? prevSelectedItems.filter((item) => item._id !== itemId)
        : [...prevSelectedItems, data];
      setSelectAllCount(newSelectedItems.length)
      handleApplySectionCheck(changeDate,changeTime,newSelectedItems.length)
      return newSelectedItems;
    });
    setSelectedItems((newSelectedItems) => {
      const allSelected = newSelectedItems.length === showData.length;
      setSelectAll(allSelected);
      return newSelectedItems;
    });
  };
  const isItemSelected = (itemId) => {
    return selectedItems.some((item) => item._id === itemId);
  };


  const handleSelectAllChangeConfirm = () => {
      setSelectedItems(showData);
      setSelectAllCount(showData.length)
      setUpdateSectionCheck(false);
      handleApplySectionCheck(changeDate,changeTime, showData.length)
      }

    const handleUnSelectAllChangeConfirm = () => {
    setSelectedItems([]);
    setSelectAllCount(0)
    setUpdateSectionCheck(true);
    handleApplySectionCheck(changeDate,changeTime,0)
    }

  const handleUpdateChange = () => {
    setUpdateSectionCheck(false);
  };
  const handleApplyButton = () => {
    const updatedMenuDetails = showData.map((dish) => {
      const isSelected = selectedItems.some((item) => item._id === dish._id);
      if (isSelected) {
        let windowData = [];
        changeDelivery.map((element) => {
          windowData.push({ title: element.title, status: element.status });
        });
        
        return {
          ...dish,
          totalServings: dish.totalServings,
          orderByDate: orderedDate(changeDate, changeTime),
          orderByTime: changeTime,
          deliveryOrPickupWindow: windowData,
        };
      }
      return dish;
    });

    let isValid =
      changeDelivery.map((value) => value.status).includes(true) 
      && changeDate !== "" 
      && changeTime !== "" 
    if (isValid && timeError === false && selectedItems.length > 0) {
      try {
       setShowData(updatedMenuDetails);
       setUpdateSectionCheck(false);
      } catch (error) {
        document.body.classList.remove("loading-indicator");
        tostE("Please fill details");
      }
    } else {
      document.body.classList.remove("loading-indicator");
      if (timeError) {
        tostE("Please select a valid time.");
      } else {
        tostE("Please fill details");
      }
    }
  };
  const handleDateError = (index, reason) => {
    if (reason !== "maxDate") {
    setDateErrors((prevErrors) => {
      let updatedErrors = [...prevErrors];
      updatedErrors[index] = reason === "disablePast";
      return updatedErrors;
    });  
  }
  }

  const handleTimeError = (index, reason) => {
    setTimeErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = reason === "maxTime" || reason === "minTime";
      return updatedErrors;
    }
  );}
  

  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>my menu</h3>
          <nav className="breadcrumb-box">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard/mymenu/all">My Menu</a>
              </li>
              <li className="breadcrumb-item">
                <a>
                  {moment(current_date).format("DD MMMM") ===
                  moment(type).format("DD MMMM")
                    ? "Today"
                    : moment(type).format("DD MMMM")}{" "}
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {" "}
                Update Menu Details
              </li>
            </ol>
          </nav>
        </div>
        <div className="dash-bottom mt-4 add-dish">
          {/* <p className="messageText">
                        Please select the number of servings available for each dish.
                    </p> */}
          {showData.map((data, index) => {
            return (
              <>
                <div className="row" style={{justifyContent:"center",alignItems:'center'}}>
                  <div className="col-md-3">
                    {index == 0 ? (
                      <>
                        <h3 className="inner-title">Delivery/Pick-Up Window</h3>

                        <div class="delivery-radio-box">
                          {changeDelivery?.map((daa, idx) => {
                            return (
                              <label className="coustom-checkbox me-3">
                                <input
                                  type="checkbox"
                                  checked={daa?.status}
                                  value={daa?.status}
                                  onChange={(e) => handleChangeTags(idx, e)}
                                />
                                <span className="checkmark"></span>
                                <p>{daa?.title}</p>
                              </label>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-3">
                    <div class="inner-title-top-dish my-menu-content">
                      {index === 0 ? (
                        <>
                          <h3 className="inner-title">
                            ORDER BY DATE
                            <span className="tool-tip-box con-tooltip top update-con-tooltip">
                              <i className="ri-information-line"></i>
                              <div className="tooltip-order-date tooltip" data-placement="bottom">
                                <p>
                       1. For Today’s menu, you can only select today’s date in this field. 
                      <br />
                       2. For future date menu, you can select any date before the scheduled order date in this field.
                      </p>
                              </div>
                            </span>
                          </h3>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {new Date(current_date).toLocaleDateString() ===
                            new Date(type).toLocaleDateString() ? (
                              // moment(current_date).format("DD MMMM") === moment(type).format("DD MMMM")
                              <DatePicker
                                className="form-control"
                                label="Select Date"
                                disablePast
                                maxDate={current_date}
                                inputProps={{ readOnly: true }}
                                showToolbar={false}
                                value={changeDate}
                                onError={(reason, value) => {
                                  if (reason == "disablePast") {
                                    setDateError(true);
                                  }
                                  if (reason == null) {
                                    setDateError(false);
                                  }
                                }}
                                onChange={(date) => handleDate(date)}
                                renderInput={(params) => (
                                  <TextField 
                                  {...params} 
                                  error={!dateError ? false: true}
                                  sx={!dateError ? DateAndTimeStyle: ''}
                                />
                                
                                
                                )}
                              />
                            ) : (
                              <DatePicker
                                className="form-control"
                                label="Select Date"
                                inputProps={{ readOnly: true }}
                                showToolbar={false}
                                disablePast
                                maxDate={moment(one)
                                  .format("MM/DD/YYYY")
                                  .split("-")
                                  .join("")}
                                value={changeDate}
                                onChange={(date) => handleDate(date)}
                                onError={(reason, value) => {
                                  if (reason == "disablePast") {
                                    setDateError(true);
                                  }
                                  if (reason == null) {
                                    setDateError(false);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} 
                                  error={!dateError ? false: true}
                                  sx={!dateError ? DateAndTimeStyle: ''}
                                  />
                                )}
                              />
                            )}
                          </LocalizationProvider>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="inner-title-top-dish my-menu-content">
                      {index === 0 ? (
                        <>
                          <h3 className="inner-title">
                            ORDER BY TIME
                            <span className="tool-tip-box con-tooltip top update-con-tooltip">
                              <i className="ri-information-line"></i>
                              <div className="tooltip" data-placement="bottom">
                              <p>
                                1. Today’s menu:
                                <br />
                                  1. For Delivery/Pick-up window of 10am-1pm, you can select ‘Order By Time’ up to 9:30am, for timely pick-up and deliveries
                                  <br />
                                  2. For Delivery/Pick-up window of 4pm-7pm, you can select ‘Order By Time’ up to 3:30pm, for timely pick-up and deliveries
                                  <br />
                                2. Future date menu, you can select any time as your Order by time
                                <br/>
                             </p>
                              </div>
                            </span>
                          </h3>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {new Date(current_date).toLocaleDateString() ===
                            new Date(state.type||state.menuDate).toLocaleDateString() ? (
                              // moment(current_date).format("DD MMMM") === moment(type).format("DD MMMM")
                              <DesktopTimePicker
                                className="form-control"
                                label="Select Time"
                                inputProps={{ readOnly: true }}
                                minTime={
                                  new Date(0, 0, 0, current_hours, current_min)
                                }
                                maxTime={
                                  changeDelivery[0].status === true &&
                                  changeDelivery[1].status === false
                                    ? new Date(0, 0, 0, 9, 30)
                                    : new Date(0, 0, 0, 3 + 12, 30)
                                }
                                value={changeTime}
                                onError={(reason, value) => {
                                  if (
                                    reason == "maxTime" ||
                                    reason == "minTime"
                                  ) {
                                    setTimeError(true);
                                  }
                                  if (reason == null) {
                                    setTimeError(false);
                                  }
                                }}
                                onChange={(time) => handleTime(time)}
                                renderInput={(params) => (
                                  <TextField {...params} 
                                    error={!timeError ? false : true}
                                    sx={!timeError ? DateAndTimeStyle: ''}
                                  />
                                )}
                              />
                            ) : (
                              <DesktopTimePicker
                                className="form-control"
                                label="Select Time"
                                inputProps={{ readOnly: true }}
                                minTime={
                                  moment(current_date).format("MM/DD/YYYY") !=
                                  moment(changeDate).format("MM/DD/YYYY")
                                    ? ""
                                    : new Date(
                                        0,
                                        0,
                                        0,
                                        current_hours,
                                        current_min
                                      )
                                }
                                // maxTime={
                                //   changeDelivery[0].status === true &&
                                //   changeDelivery[1].status === false
                                //     ? new Date(0, 0, 0, 9, 30)
                                //     : new Date(0, 0, 0, 3 + 12, 30)
                                // }
                                maxTime={
                                  changeDelivery[0].status === true &&
                                  changeDelivery[1].status === false
                                    ? moment(new Date(state.type||state.menuDate)).format(
                                        "MM/DD/YYYY"
                                      ) ===
                                      moment(changeDate).format("MM/DD/YYYY")
                                      ? new Date(0, 0, 0, 9, 30)
                                      : ""
                                    : moment(new Date(state.type||state.menuDate)).format(
                                        "MM/DD/YYYY"
                                      ) ===
                                      moment(changeDate).format("MM/DD/YYYY")
                                    ? new Date(0, 0, 0, 3 + 12, 30)
                                    : ""
                                }
                                // value={`${location.state?.orderByTime?.hours}` + `:${location.state?.orderByTime?.minutes}` + " " + location.state?.orderByTime?.format}
                                // value={moment(location.state.orderByTime).format("YYYY-MM-DD'T'HH:MM:SS.SSSZ")}
                                onError={(reason, value) => {
                                  if (
                                    reason == "maxTime" ||
                                    reason == "minTime"
                                  ) {
                                    setTimeError(true);
                                  }
                                  if (reason == null) {
                                    setTimeError(false);
                                  }
                                }}
                                value={changeTime}
                                onChange={(time) => handleTime(time)}
                                renderInput={(params) => (
                                  <TextField {...params} 
                                  error={!timeError ? false : true}
                                  sx={!timeError ? DateAndTimeStyle: ''}
                                  />
                                )}
                              />
                            )}
                          </LocalizationProvider>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 update_button">
                    <div class="inner-title-top-dish">
                      {index === 0 ? (
                        <>
                          <div className="col-md-3">
                            <button
                              className="btn purple-btn min-height hvr-sweep-to-right"
                              style={{marginTop: "2.2rem" }}
                              disabled={applySectionCheck}
                              onClick={handleApplyButton}
                            >
                              Apply To Selected Dishes
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {index === 0 ? (
                <div>
                    <p className="messageText">
                    Please select the number of servings available for each
                    dish.
                  </p>
                  <div className="filter-menu-box">
                  <span className="number-of-select">
                    <i className="ri-check-line"></i>
                    <h6 className="me-3">{selectAllCount}</h6>
                  </span>
                  <button
                    className="select-all-box"
                    style={{ cursor: "pointer", border: "#fff" }}
                    onClick={()=>handleSelectAllChangeConfirm()}
                  >
                    Select All
                  </button>
                  <button
                    className="Unselect-box"
                    style={{ cursor: "pointer", border: "#fff" }}
                    onClick={() => handleUnSelectAllChangeConfirm()}
                  >
                    Unselect
                  </button>
                </div>
                </div>
                ) : (
                  ""
                )}
                <div className="row mt-6" style={{alignItems:"center"}}>
                  <div className="col-md-3 all-dish-item me-1">
                    {index === 0 ? (
                      <h3 className="inner-title" style={{paddingTop:"20px"}}>DISH NAME</h3>
                    ) : (
                      ""
                    )}
                    <div className="add-dish-list mb-3 ">
                      <div className="dish-name">
                        <div className="dish-name-content">
                          <div class="delivery-radio-box">
                            <label className="coustom-checkbox me-3 mb-0 custom-radiobox">
                              <input
                                type="checkbox"
                                checked={isItemSelected(data._id)}
                                onChange={() => handleApply(data, index)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <figure>
                            <img src={ImageURL + data.dishImage} />
                          </figure>
                          <figcaption>
                            <h3>
                            {data.name}
                            </h3>
                          </figcaption>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-1  all-dish-item all-items-delete p-0 me-2">
                    <div className="Servings">
                      {index === 0 ? (
                        <h3 className="inner-title">SERVINGS</h3>
                      ) : (
                        ""
                      )}
                      <div className="d-flex align-items-center mt-3">
                        <div
                          className={
                            location.state.totalServings === 0
                              ? "number number-unactive"
                              : "number"
                          }
                        >
                          <span className="minus" style={{fontSize:"15px"}}>
                            {data.totalServings != 0 ? (
                              <i
                                className="ri-subtract-fill"
                                onClick={(e) => handleupdate("minus", index, e)}
                              ></i>
                            ) : null}
                          </span>

                          <input
                            type="text"
                            name="servings"
                            value={data.totalServings}
                            onChange={(e) => handleupdate("type", index, e)}
                            min={0}
                            maxLength={3}
                          />
                          <span className="plus">
                            <i
                              className="ri-add-line"
                              onClick={(e) => handleupdate("add", index, e)}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3  all-dish-item all-items-delete me-1">
                    <div className="Servings">
                      {index === 0 ? (
                        <h3 className="inner-title text-center">Delivery/Pick-Up Window</h3>
                      ) : (
                        ""
                      )}
                      <div
                        class="delivery-radio-box"
                        style={{ marginTop: "1rem",justifyContent:'center' }}
                      >
                        {data?.deliveryOrPickupWindow?.map((data, idx) => {
                          return (
                            <label className="coustom-checkbox me-3 mb-0">
                              <input
                                type="checkbox"
                                checked={data?.status}
                                value={data?.status}
                                onChange={(e) =>
                                  handleChangeTags1(index, idx, e)
                                }
                              />
                              <span className="checkmark"></span>
                              <p>{data?.title}</p>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2  all-dish-item all-items-delete me-1">
                    <div className="Servings inner-title-top-dish my-menu-content">
                      {index === 0 ? (
                        <h3 className="inner-title">
                          ORDER BY DATE
                          <span className="tool-tip-box con-tooltip top update-con-tooltip">
                            <i className="ri-information-line"></i>
                            <div className="tooltip tooltip-order-date">
                            <p>
                       1. For Today’s menu, you can only select today’s date in this field. 
                      <br />
                       2. For future date menu, you can select any date before the scheduled order date in this field.
                      </p>
                        </div>
                          </span>
                        </h3>
                      ) : (
                        ""
                      )}
                      <div className="date_box" style={{ marginTop: "1rem" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          {new Date(current_date).toLocaleDateString() ===
                          new Date(type).toLocaleDateString() ? (
                            <DatePicker
                              className="form-control"
                              label="Select Date"
                              inputProps={{ readOnly: true }}
                              disablePast
                              maxDate={current_date}
                              showToolbar={false}
                              onError={(reason, value) => handleDateError(index, reason)}
                              value={data?.orderByDate ? new Date(data?.orderByDate) : null}
                              onChange={(date) =>
                                handleOrderByDateTags1(index, date)
                              }
                              renderInput={(params) => (
                                <TextField
                                  style={{ backgroundColor: "#f7f7f7" }}
                                  {...params}
                                  sx={DateAndTimeStyle}
                                />
                              )}
                            />
                          ) : (
                            <DatePicker
                              className="form-control"
                              label="Select Date"
                              inputProps={{ readOnly: true }}
                              disablePast
                              maxDate={moment(one)
                                .format("MM/DD/YYYY")
                                .split("-")
                                .join("")}
                                onError={(reason, value) => handleDateError(index, reason)}
                              // maxDate={
                              //   moment(new Date(state.menuDate)).subtract(1, "d")._d
                              // }
                              showToolbar={false}
                              value={data?.orderByDate ? new Date(data?.orderByDate) : null}
                              onChange={(date) =>
                                handleOrderByDateTags1(index, date)
                              }
                              renderInput={(params) => (
                                <TextField
                                  style={{ backgroundColor: "#f7f7f7" }}
                                  {...params}
                                  sx={DateAndTimeStyle}
                                />
                              )}
                            />
                          )}
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 all-dish-item all-items-delete me-1">
                    <div className="Servings inner-title-top-dish my-menu-content">
                      {index === 0 ? (
                        <h3 className="inner-title">
                          ORDER BY TIME
                          <span className="tool-tip-box con-tooltip update-con-tooltip">
                            <i className="ri-information-line"></i>
                            <div className="tooltip tooltip-under" data-placement="top">
                            <p>
                      1. Today’s menu:
                      <br />
                        1. For Delivery/Pick-up window of 10am-1pm, you can select ‘Order By Time’ up to 9:30am, for timely pick-up and deliveries
                        <br />
                        2. For Delivery/Pick-up window of 4pm-7pm, you can select ‘Order By Time’ up to 3:30pm, for timely pick-up and deliveries
                        <br />
                      2. Future date menu, you can select any time as your Order by time
                      <br/>
                      </p>
                            </div>
                          </span>
                        </h3>
                      ) : (
                        ""
                      )}
                      <div className="time_box" style={{ marginTop: "1rem" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          {new Date(current_date).toLocaleDateString() ===
                          new Date(state.type||state.menuDate).toLocaleDateString() ? (
                            // moment(current_date).format("DD-MM-YYYY") === moment(new Date(state.menuDate?.split("00").join(""))).format("DD-MM-YYYY")
                            <DesktopTimePicker
                              className="form-control"
                              label="Select Time"
                              minTime={
                                new Date(0, 0, 0, current_hours, current_min)
                              } // 7:00am
                              maxTime={
                                data.deliveryOrPickupWindow[0].status === true &&
                                data.deliveryOrPickupWindow[1].status === false
                                  ? new Date(0, 0, 0, 9, 30)
                                  : new Date(0, 0, 0, 3 + 12, 30)
                              }
                              inputProps={{ readOnly: true }}
                              value={data?.orderByDate ? new Date(data?.orderByDate) : null}
                              onChange={(date) =>
                                handleOrderByTimeTags1(index, date)
                              }
                              onError={(reason, value) => handleTimeError(index, reason)}
                              renderInput={(params) => (
                                <TextField
                                  style={{ backgroundColor: "#f7f7f7" }}
                                  {...params}
                                  sx={DateAndTimeStyle}
                                />
                              )}
                            />
                          ) : (
                            <DesktopTimePicker
                              className="form-control"
                              label="Select Time"
                              inputProps={{ readOnly: true }}
                              value={data?.orderByDate ? new Date(data?.orderByDate) : null}
                              onChange={(date) =>
                                handleOrderByTimeTags1(index, date)
                              }
                              minTime={
                                moment(current_date).format("MM/DD/YYYY") !=
                                moment(data.orderByDate).format("MM/DD/YYYY")
                                  ? ""
                                  : new Date(
                                      0,
                                      0,
                                      0,
                                      current_hours,
                                      current_min
                                    )
                              }
                              // maxTime={
                              //   changeDelivery[0].status === true &&
                              //   changeDelivery[1].status === false
                              //     ? new Date(0, 0, 0, 9, 30)
                              //     : new Date(0, 0, 0, 3 + 12, 30)
                              // }
                              maxTime={
                                data.deliveryOrPickupWindow[0]?.status === true &&
                                data.deliveryOrPickupWindow[1]?.status === false
                                  ? moment(new Date(state.type||state.menuDate)).format(
                                      "MM/DD/YYYY"
                                    ) ===
                                    moment(new Date(data.orderByDate)).format(
                                      "MM/DD/YYYY"
                                    )
                                    ? new Date(0, 0, 0, 9, 30)
                                    : ""
                                  : moment(new Date(state.type||state.menuDate)).format(
                                      "MM/DD/YYYY"
                                    ) ===
                                    moment(new Date(data.orderByDate)).format(
                                      "MM/DD/YYYY"
                                    )
                                  ? new Date(0, 0, 0, 3 + 12, 30)
                                  : ""
                              }
                              onError={(reason, value) => handleTimeError(index, reason)}
                              renderInput={(params) => (
                                <TextField
                                  style={{ backgroundColor: "#f7f7f7" }}
                                  {...params}
                                  sx={DateAndTimeStyle}
                                />
                              )}
                            />
                          )}
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className="mt-4 btn-group-box mb-3">
            <button
              className="btn purple-btn min-height hvr-sweep-to-right me-3"
              onClick={submit}
              disabled={updateSectionCheck}
            >
              Update
            </button>
            <button
              className="btn purple-btn min-height hvr-sweep-to-right trans-btn"
              onClick={() => addDish()}
            >
              Add More Dishes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateMenu;
