import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
      color="#8d126e"
    >
      <path d="M17.005 16.003h2v-12h-10v2h8v10zm0 2v2.999c0 .553-.45 1-1.007 1H4.012a1.001 1.001 0 01-1.007-1l.002-13.998c0-.553.45-1.001 1.007-1.001h2.99v-3a1 1 0 011-1h12a1 1 0 011 1v14a1 1 0 01-1 1h-3zm-11.998-10l-.002 12h10v-12H5.007zm1.998 8h4.5a.5.5 0 100-1h-3a2.5 2.5 0 110-5h.5v-1h2v1h2v2h-4.5a.5.5 0 000 1h3a2.5 2.5 0 010 5h-.5v1h-2v-1h-2v-2z"></path>
    </svg>
  );
}

export default Icon;
