import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DishDetailsURL } from "../../component/config/config";
import { AddMenuDetailsURL } from "../../component/config/config";
import moment from "moment-timezone";
import { ImageURL } from "../../component/config/config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { tostS, tostE } from "../../toast";
import axiosInstance from "../config/axios";
import {
  datefiltered,
  orderedDate,
  filterPassedTime,
} from "../../utility/DateFormat";

const AddMenuDetails = () => {
  const DateAndTimeStyle={
    "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
      top: "-10px",
    fontSize: "12px"
    },
    ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":{
      fontSize:"12px",
    },
  }
  const navigate = useNavigate();
  const location = useLocation();
  const current_date = new Date();
  let current_hours = current_date.getHours();
  let current_min = current_date.getMinutes();
  let { type } = useParams();
  const { state } = useLocation();
  const [menuDetails, setMenuDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [timeErrors, setTimeErrors] = useState([]);
  const [dateErrors, setDateErrors] = useState([]); 
  const [selectedItems, setSelectedItems] = useState([]);
  const [applySectionCheck, setApplySectionCheck] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllCount,setSelectAllCount]=useState(0)


  const fetchData = async () => {
    let pastData = location.state.menu;
    setLoading(true);
    axiosInstance()
      .post(DishDetailsURL, { dishIds: state.dishIds })
      .then((response) => {
        let arr = [];
        response.data.data.map((dish) => {
          let prevDish = pastData && pastData.filter((D) => D._id === dish._id);
          if (
            prevDish !== null &&
            prevDish !== undefined &&
            prevDish.length > 0
          ) {
            arr.push({ ...prevDish[0] });
            // setChangeDate(prevDish[0].orderByDate);
            // setChangeTime(prevDish[0].orderByTime);
            // setChangeDelivery(prevDish[0].deliveryOrPickupWindow);
          } else {
            arr.push({
              ...dish,
              totalServings: 0,
              orderByDate: "",
              orderByTime: "",
              deliveryOrPickupWindow: [
                {
                  title: "10am-1pm",
                  status: false,
                },
                {
                  title: "4pm-7pm",
                  status: false,
                },
              ],
            });
          }
        });
        setMenuDetails(arr);
        // setChangeDate(arr.orderByDate)
        // setChangeTime(arr.orderByTime)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const submit = (e) => {
    e.preventDefault();
    document.body.classList.add("loading-indicator");
    let arr = [];
    arr = menuDetails.map((dish) => {
      let windowData = [];
      dish.deliveryOrPickupWindow.map((element) => {
        if (element.status) {
          windowData.push(element.title);
        }
      });
      return {
        dishId: dish._id,
        totalServings: dish.totalServings,
        orderByDate: orderedDate(dish.orderByDate, dish.orderByTime),
        orderByTime: {
          hours: moment(dish.orderByTime).format("h"),
          minutes: moment(dish.orderByTime).format("mm"),
          format: moment(dish.orderByTime).format("a"),
        },
        deliveryOrPickupWindow: windowData,

      };
    });
    let isValid = arr.map((_, i) => {
      const isDateAndTimeValid =
        arr[i]?.totalServings != 0 &&
        !arr[i]?.orderByDate.includes("Invalid Date") &&
        arr[i]?.orderByTime.hours !== "Invalid date" &&
        arr[i]?.orderByTime.minutes !== "Invalid date" &&
        arr[i]?.orderByTime.format !== "Invalid date" &&
        arr[i].deliveryOrPickupWindow.length > 0 
    
      const isWindowValid =
        arr[i]?.totalServings != 0 &&
        arr[i].deliveryOrPickupWindow.length > 0 &&
        changeDate !== "" &&
        changeTime !== "";
        
        if (!isDateAndTimeValid && isWindowValid) {
          return false;
        }
        return isDateAndTimeValid || isWindowValid;
    });
    if (isValid.every((_) => _) && timeErrors.filter(error => error === false || error === true).every((error) => error === false) && dateErrors.filter(error => error === false || error === true).every((error) => error === false)) {
      try {
        setIsDisabled(true);
        axiosInstance()
          .post(AddMenuDetailsURL, {
            dishes: arr,
            menuDate: state.menuDate,
            now: new Date().toLocaleString(),
          })
          .then((response) => {
            document.body.classList.remove("loading-indicator");
            state.menuDate = moment(state.menuDate).format("DD-MMMM-YYYY");
            navigate(`/dashboard/mymenu/${state?.menuDate}`);
            tostS(response.data.message);
          });
      } catch (error) {
        document.body.classList.remove("loading-indicator");
        console.log(error);
        setIsDisabled(false);
        tostE("Please fill details");
      }
    } else {
      document.body.classList.remove("loading-indicator");
      if (timeErrors.filter(error => error === false || error === true).some((error) => error === true)) {
        tostE("Please select a valid time.");
      } else if (dateErrors.filter(error => error === false || error === true).some((error) => error === true)) {
        tostE("Please select a valid date.");
      } else {
        tostE("Please fill details");
      }
    }
  };
  const handleupdate = (operation, index, e) => {
    let zipReg = /^\d+$/;
    let v = e.target.value;
    let arr = [...menuDetails];
    if (operation === "add") {
      arr[index].totalServings++;
    }
    if (operation === "minus") {
      arr[index].totalServings--;
    }
    if ((operation === "type" && zipReg.test(v)) || v === "") {
      if (v.length <= 3) {
        arr[index].totalServings = v;
      }
    }
    setMenuDetails(arr);
  };
  const handleChangeTags1 = (index, idx, e) => {
    let arr = [...menuDetails];
    arr[index].deliveryOrPickupWindow[idx].status =
      !arr[index].deliveryOrPickupWindow[idx].status;
    setMenuDetails(arr);
  };
  const handleOrderByDateTags1 = (index, date) => {
    let arr = [...menuDetails];
    arr[index].orderByDate = date;
    setMenuDetails(arr);
  };

  const handleOrderByTimeTags1 = (index, date) => {
    let arr = [...menuDetails];
    arr[index].orderByTime = date;
    setMenuDetails(arr);
  };

  const addDish = () => {
    navigate(
      `/dashboard/mymenu/${moment(state.menuDate).format("DD-MMMM-YYYY")}`,
      { state: { data: handleChangedData(), type: "add" } }
    );
  };
  const handleChangedData = () => {
    let arr = [...menuDetails];
    const updateArr = arr.map((item) => ({
      ...item,
      orderByDate: item.orderByDate,
      orderByTime: item.orderByTime,
      deliveryOrPickupWindow: item.deliveryOrPickupWindow,
    }));
    return updateArr;
  };
  const handleDate = (value, index) => {
    let arr = [...menuDetails];
    arr[index].orderByDate = value;
    setMenuDetails([...arr]);
  };
  const handleTime = (value, index) => {
    let arr = [...menuDetails];
    arr[index].orderByTime = value;
    setMenuDetails([...arr]);
  };
  const handleApplySectionCheck = (updatedDate, updatedTime, menuDetailsLength) => {
    const hasValidDelivery = (changeDelivery[0]?.status || changeDelivery[1]?.status);
    const isValid = hasValidDelivery && updatedDate && updatedTime && menuDetailsLength > 0;
    setApplySectionCheck(!isValid);
  };

  const handleChangeTags = (i, e, index) => {
    const a = [...changeDelivery];
    a[i].status = e.target.checked;
    setChangeDelivery([...a]);
    handleApplySectionCheck(changeDate,changeTime,selectedItems.length)
  };

  const handleApply = (damm, index) => {
    setSelectedItems((prevSelectedItems) => {
      const itemId = damm._id;
      const isSelected = prevSelectedItems.some((item) => item._id === itemId);
      const newSelectedItems = isSelected
        ? prevSelectedItems.filter((item) => item._id !== itemId)
        : [...prevSelectedItems, damm];
      setSelectAllCount(newSelectedItems.length)
      handleApplySectionCheck(changeDate,changeTime,newSelectedItems.length)
      return newSelectedItems;
    });
    setSelectedItems((newSelectedItems) => {
      const allSelected = newSelectedItems.length === menuDetails.length;
      setSelectAll(allSelected);
      return newSelectedItems;
    });
  };

  


  const handleSelectAllChangeConfirm = () => {
    setSelectedItems(menuDetails);
    setSelectAllCount(menuDetails.length)
    handleApplySectionCheck(changeDate,changeTime,menuDetails.length)
    }

  const handleUnSelectAllChangeConfirm = () => {
  setSelectedItems([]);
  setSelectAllCount(0)
  handleApplySectionCheck(changeDate,changeTime,0)
  }

  const isItemSelected = (itemId) => {
    return selectedItems.some((item) => item._id === itemId);
  };

  const handleApplyButton = () => {
    const updatedMenuDetails = menuDetails.map((dish) => {
      const isSelected = selectedItems.some((item) => item._id === dish._id);
      if (isSelected) {
        let windowData = [];
        changeDelivery.map((element) => {
          windowData.push({ title: element.title, status: element.status });
        });
        return {
          ...dish,
          totalServings: dish.totalServings,
          orderByDate: orderedDate(changeDate, changeTime),
          orderByTime: changeTime,
          deliveryOrPickupWindow: windowData,
        };
      }
      return dish;
    });
    let isValid =
      changeDelivery.map((value) => value.status).includes(true) &&
      changeDate !== "" &&
      changeTime !== "";

    if (isValid && timeError === false && selectedItems.length > 0) {
      try {
        setMenuDetails(updatedMenuDetails);
        setIsDisabled(false);
      } catch (error) {
        document.body.classList.remove("loading-indicator");
        tostE("Please fill details");
      }
    } else {
      document.body.classList.remove("loading-indicator");
      if (timeError) {
        tostE("Please select a valid time.");
      } else {
        tostE("Please fill details");
      }
      return false;
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...menuDetails];
    newFormValues.splice(i, 1);
    setMenuDetails(newFormValues);
    setSelectAllCount(newFormValues.length)
  };
  const [changeDate, setChangeDate] = useState("");
  const [changeTime, setChangeTime] = useState("");
  const [changeDelivery, setChangeDelivery] = useState([
    {
      title: "10am-1pm",
      status: false,
    },
    {
      title: "4pm-7pm",
      status: false,
    },
  ]);

  const handleDateError = (index, reason) => {
    if (reason !== "maxDate") {
    setDateErrors((prevErrors) => {
      let updatedErrors = [...prevErrors];
      updatedErrors[index] = reason === "disablePast";
      return updatedErrors;
    });  
  }
  }

  const handleTimeError = (index, reason) => {
    setTimeErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = reason === "maxTime" || reason === "minTime";
      return updatedErrors;
    });
  };
  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>my menu</h3>
          <nav className="breadcrumb-box">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard/mymenu/all">My Menu</a>
              </li>
              <li className="breadcrumb-item">
                <a>
                  {/* {state.menuDate.charAt(0).includes('-') ? state.menuDate = state.menuDate.slice(1) : ""} */}
                  {moment(current_date).format("DD MMMM") ===
                  moment(state.menuDate).format("DD MMMM")
                    ? "Today"
                    : moment(state.menuDate).format("DD MMMM")}
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Add Menu Details
              </li>
            </ol>
          </nav>
        </div>
        <div className="dash-bottom mt-2 add-dish">
          <div className="row mt-4 delivery-main-head" style={{alignItems:"center"}}>
            <div className="col-md-3">
              <h3 className="inner-title">Delivery/Pick-Up Window</h3>
              <div class="delivery-radio-box">
                {changeDelivery?.map((data, idx) => {
                  return (
                    <label className="coustom-checkbox me-3 mb-0">
                      <input
                        type="checkbox"
                        checked={data.status}
                        value={data.status}
                        onChange={(e) => handleChangeTags(idx, e)}
                      />
                      <span className="checkmark"></span>
                      <p>{data.title}</p>
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="col-md-3">
              <div class="inner-title-top-dish my-menu-content">
                <h3 className="inner-title">
                  ORDER BY DATE
                  <span className="tool-tip-box con-tooltip top">
                    <i className="ri-information-line"></i>
                    <div className="tooltip tooltip-order-date">
                      <p>
                      1. For Today’s menu, you can only select today’s date in this field. 
                      <br />
                      2. For future date menu, you can select any date before the scheduled order date in this field.
                      </p>
                    </div>
                  </span>
                </h3>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {new Date(current_date).toLocaleDateString() ===
                  new Date(state.menuDate).toLocaleDateString() ? (
                    <DatePicker
                      className="form-control"
                      label="Select Date"
                      inputProps={{ readOnly: true }}
                      disablePast
                      maxDate={current_date}
                      showToolbar={false}
                      value={changeDate}
                      onChange={(e)=>{
                        setChangeDate(e);
                        handleApplySectionCheck(e, changeTime,selectedItems.length)
                      }}
                      renderInput={(params) => <TextField {...params} 
                      // error={true}
                      />}
                    />
                  ) : (
                    <DatePicker
                      className="form-control"
                      label="Select Date"
                      inputProps={{ readOnly: true }}
                      disablePast
                      maxDate={moment(new Date(state.menuDate))}
                      // maxDate={
                      //   moment(new Date(state.menuDate)).subtract(1, "d")._d
                      // }
                      showToolbar={false}
                      value={changeDate}
                      onChange={(e)=>{
                        setChangeDate(e);
                        handleApplySectionCheck(e, changeTime,selectedItems.length)
                      }}
                      renderInput={(params) => <TextField {...params} 
                      // error={true}        
                      />}
                    />
                  )}
                </LocalizationProvider>
              </div>
            </div>
            <div className="col-md-3">
              <div class="inner-title-top-dish my-menu-content">
                <h3 className="inner-title">
                  ORDER BY TIME
                  <span className="tool-tip-box con-tooltip top">
                    <i className="ri-information-line"></i>
                    <div className="tooltip " data-placement="bottom">
                      <p>
                      1. Today’s menu:
                      <br />
                        1. For Delivery/Pick-up window of 10am-1pm, you can select ‘Order By Time’ up to 9:30am, for timely pick-up and deliveries
                        <br />
                        2. For Delivery/Pick-up window of 4pm-7pm, you can select ‘Order By Time’ up to 3:30pm, for timely pick-up and deliveries
                        <br />
                      2. Future date menu, you can select any time as your Order by time
                      <br/>
                      </p>
                    </div>
                  </span>
                </h3>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {new Date(current_date).toLocaleDateString() ===
                  new Date(state.menuDate).toLocaleDateString() ? (
                    // moment(current_date).format("DD-MM-YYYY") === moment(new Date(state.menuDate?.split("00").join(""))).format("DD-MM-YYYY")
                    <DesktopTimePicker
                      className="form-control"
                      label="Select Time"
                      minTime={new Date(0, 0, 0, current_hours, current_min)} // 7:00am
                      maxTime={
                        changeDelivery[0].status === true &&
                        changeDelivery[1].status === false
                          ? new Date(0, 0, 0, 9, 30)
                          : new Date(0, 0, 0, 3 + 12, 30)
                      }
                      inputProps={{ readOnly: true }}
                      value={changeTime}
                      onError={(reason, value) => {
                        if (reason == "maxTime" || reason == "minTime") {
                          setTimeError(true);
                        }
                        if (reason == null) {
                          setTimeError(false);
                        }
                      }}
                      onChange={(e)=>{
                        setChangeTime(e);
                        handleApplySectionCheck(changeDate, e,selectedItems.length)
                      }}
                      renderInput={(params) => <TextField {...params} 
                      // error={true}
                      />}
                    />
                  ) : (
                    <DesktopTimePicker
                      className="form-control"
                      label="Select Time"
                      inputProps={{ readOnly: true }}
                      value={changeTime}
                      minTime={
                        moment(current_date).format("MM/DD/YYYY") !=
                        moment(changeDate).format("MM/DD/YYYY")
                          ? ""
                          : new Date(0, 0, 0, current_hours, current_min)
                      }
                      // maxTime={
                      //   changeDelivery[0].status === true &&
                      //   changeDelivery[1].status === false
                      //     ? new Date(0, 0, 0, 9, 30)
                      //     : new Date(0, 0, 0, 3 + 12, 30)
                      // }
                      maxTime={
                        changeDelivery[0].status === true &&
                        changeDelivery[1].status === false
                          ? moment(new Date(state.menuDate)).format(
                              "MM/DD/YYYY"
                            ) ===
                            moment(new Date(changeDate)).format("MM/DD/YYYY")
                            ? new Date(0, 0, 0, 9, 30)
                            : ""
                          : moment(new Date(state.menuDate)).format(
                              "MM/DD/YYYY"
                            ) ===
                            moment(new Date(changeDate)).format("MM/DD/YYYY")
                          ? new Date(0, 0, 0, 3 + 12, 30)
                          : ""
                      }
                      onChange={(e)=>{
                        setChangeTime(e);
                        handleApplySectionCheck(changeDate, e,selectedItems.length)
                      }}
                      onError={(reason, value) => {
                        if (reason == "maxTime" || reason == "minTime") {
                          setTimeError(true);
                        }
                        if (reason == null) {
                          setTimeError(false);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} 
                      // error={true}
                      />}
                    />
                  )}
                </LocalizationProvider>
              </div>
            </div>
            <div className="col-md-3">
              <button
                className="btn purple-btn min-height hvr-sweep-to-right  me-3"
                style={{ width: "80%", marginTop: "2.2rem" }}
                disabled={applySectionCheck}
                onClick={handleApplyButton}
              >
                Apply To Selected Dishes
              </button>
            </div>
          </div>
          <p className="messageText">
            Please select the number of servings available for each dish.
          </p>
          <div className="filter-menu-box">
                  <span className="number-of-select">
                    <i className="ri-check-line"></i>
                    <h6 className="me-3">{selectAllCount}</h6>
                  </span>
                  <button
                    className="select-all-box"
                    style={{ cursor: "pointer", border: "#fff" }}
                    onClick={()=>handleSelectAllChangeConfirm()}
                  >
                    Select All
                  </button>
                  <button
                    className="Unselect-box"
                    style={{ cursor: "pointer", border: "#fff" }}
                    onClick={() => handleUnSelectAllChangeConfirm()}
                  >
                    Unselect
                  </button>
                </div>

          <div class="dish-list">
         
            <div class="dish-list-inner">
              <div className="row dish-action-box mt-4" style={{alignItems:"center"}}>
                <div className="col-md-3 col-5 all-dish-item">
                  <h3 className="inner-title">DISH NAME</h3>
                </div>
                <div className="col-md-1 col-4 all-dish-item p-0">
                  <h3 className="inner-title">SERVINGS</h3>
                </div>
                <div className="col-md-3 col-3 all-dish-item text-center">
                  <h3 className="inner-title">Delivery/Pick-Up Window</h3>
                </div>
                <div className="col-md-2 col-3 all-dish-item inner-title-top-dish my-menu-content">
                  <h3 className="inner-title">
                    ORDER BY DATE
                    <span className="tool-tip-box con-tooltip update-con-tooltip">
                      <i className="ri-information-line"></i>
                      <div className="tooltip tooltip-order-date">
                      <p>
                       1. For Today’s menu, you can only select today’s date in this field. 
                      <br />
                       2. For future date menu, you can select any date before the scheduled order date in this field.
                      </p>
                    </div>
                    </span>
                  </h3>
                </div>
                <div className="col-md-2 col-3 all-dish-item inner-title-top-dish my-menu-content">
                  <h3 className="inner-title">
                    ORDER BY TIME
                    <span className="tool-tip-box con-tooltip update-con-tooltip">
                      <i className="ri-information-line"></i>
                      <div className="tooltip tooltip-under" data-placement="bottom">
                      <p>
                      1. Today’s menu:
                      <br />
                        1. For Delivery/Pick-up window of 10am-1pm, you can select ‘Order By Time’ up to 9:30am, for timely pick-up and deliveries
                        <br />
                        2. For Delivery/Pick-up window of 4pm-7pm, you can select ‘Order By Time’ up to 3:30pm, for timely pick-up and deliveries
                        <br />
                      2. Future date menu, you can select any time as your Order by time
                      <br/>
                      </p>
                      </div>
                    </span>
                  </h3>
                </div>
                <div className="col-md-1 col-3 all-dish-item "></div>
              </div>
              {menuDetails.map((damm, index) => {
                return (
                  <>
                    <div
                      className="row dish-action-box mb-4 align-items-center"
                      style={{marginTop:"0.5rem"}}
                      key={index}
                    >
                      <div className="col-md-3 col-5 all-dish-item">
                        <div className="add-dish-list ">
                          <div className="dish-name">
                            <div className="dish-name-content">
                              <div class="delivery-radio-box">
                                <label className="coustom-checkbox me-3 mb-0 custom-radiobox">
                                  <input
                                    type="checkbox"
                                    checked={isItemSelected(damm._id)}
                                    onChange={() => handleApply(damm, index)}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <figure>
                                <img src={ImageURL + damm.dishImage} />
                              </figure>
                              <figcaption>
                              <h3>
                                {damm.name}
                                </h3>
                              </figcaption>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1 col-4  all-dish-item all-items-delete p-0">
                        <div className="Servings">
                          <div className="d-flex align-items-center">
                            <div
                              className={
                                damm.totalServings === 0
                                  ? "number number-unactive"
                                  : "number"
                              }
                            >
                              <span className="minus">
                                {damm.totalServings != 0 ? (
                                  <i
                                    className="ri-subtract-fill"
                                    onClick={(e) =>
                                      handleupdate("minus", index, e)
                                    }
                                  ></i>
                                ) : null}
                              </span>
                              <input
                                type="text"
                                name="servings"
                                value={damm.totalServings}
                                onChange={(e) => handleupdate("type", index, e)}
                                min={0}
                                maxLength={3}
                              />
                              <span className="plus">
                                <i
                                  className="ri-add-line"
                                  onClick={(e) => handleupdate("add", index, e)}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-3 all-dish-item all-items-delete">
                        <div className="">
                          <div class="delivery-radio-box delivery-radio-box1">
                            {damm?.deliveryOrPickupWindow?.map((data, idx) => {
                              return (
                                <label className="coustom-checkbox me-3 mb-0">
                                  <input
                                    type="checkbox"
                                    checked={data.status}
                                    value={data.status}
                                    onChange={(e) =>
                                      handleChangeTags1(index, idx, e)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                  <p>{data.title}</p>
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-3 all-dish-item all-items-delete">
                        <div className="date_box">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {new Date(current_date).toLocaleDateString() ===
                            new Date(state.menuDate).toLocaleDateString() ? (
                              <DatePicker
                                className="form-control"
                                label="Select Date"
                                inputProps={{ readOnly: true }}
                                disablePast
                                maxDate={current_date}
                                onError={(reason, value) => handleDateError(index, reason)}
                                showToolbar={false}
                                value={
                                  damm.orderByDate
                                }
                                onChange={(date) =>
                                  handleOrderByDateTags1(index, date)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    style={{ backgroundColor: "#f7f7f7" }}
                                    {...params}
                                  />
                                )}
                              />
                            ) : (
                              <DatePicker
                                className="form-control"
                                label="Select Date"
                                inputProps={{ readOnly: true }}
                                disablePast
                                maxDate={moment(new Date(state.menuDate))}
                                onError={(reason, value) => handleDateError(index, reason)}
                                // maxDate={
                                //   moment(new Date(state.menuDate)).subtract(1, "d")._d
                                // }
                                showToolbar={false}
                                value={
                                  damm.orderByDate
                                }
                                onChange={(date) =>
                                  handleOrderByDateTags1(index, date)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    style={{ backgroundColor: "#f7f7f7" }}
                                    {...params}
                                  />
                                )}
                              />
                            )}
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="col-md-2 col-3 all-dish-item all-items-delete">
                        <div className="time_box">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {new Date(current_date).toLocaleDateString() ===
                            new Date(state.menuDate).toLocaleDateString() ? (
                              // moment(current_date).format("DD-MM-YYYY") === moment(new Date(state.menuDate?.split("00").join(""))).format("DD-MM-YYYY")
                              <DesktopTimePicker
                                className="form-control"
                                label="Select Time"
                                minTime={new Date(0, 0, 0, current_hours, current_min)} // 7:00am
                                maxTime={
                                  damm.deliveryOrPickupWindow[0].status === true &&
                                  damm.deliveryOrPickupWindow[1].status === false
                                    ? new Date(0, 0, 0, 9, 30)
                                    : new Date(0, 0, 0, 3 + 12, 30)
                                }
                                inputProps={{ readOnly: true }}
                                value={
                                  damm.orderByTime
                                }
                                onChange={(date) =>
                                  handleOrderByTimeTags1(index, date)
                                }
                                onError={(reason, value) => handleTimeError(index, reason)}
                                renderInput={(params) => (
                                  <TextField
                                    style={{ backgroundColor: "#f7f7f7" }}
                                    {...params}
                                    
                                  />
                                )}
                              />
                            ) : (
                              <DesktopTimePicker
                                className="form-control"
                                label="Select Time"
                                inputProps={{ readOnly: true }}
                                value={
                                  damm.orderByTime
                                }
                                onChange={(date) =>
                                  handleOrderByTimeTags1(index, date)
                                }
                                minTime={
                                  moment(current_date).format("MM/DD/YYYY") !=
                                  moment(damm.orderByDate).format("MM/DD/YYYY")
                                    ? ""
                                    : new Date(0, 0, 0, current_hours, current_min)
                                }
                                // maxTime={
                                //   changeDelivery[0].status === true &&
                                //   changeDelivery[1].status === false
                                //     ? new Date(0, 0, 0, 9, 30)
                                //     : new Date(0, 0, 0, 3 + 12, 30)
                                // }
                                maxTime={
                                  damm.deliveryOrPickupWindow[0].status === 
                                  true &&
                                  damm.deliveryOrPickupWindow[1].status === 
                                  false
                                    ? moment(new Date(state.menuDate)).format(
                                        "MM/DD/YYYY"
                                      ) ===
                                      moment(new Date(damm.orderByDate)).format("MM/DD/YYYY")
                                      ? new Date(0, 0, 0, 9, 30)
                                      : ""
                                    : moment(new Date(state.menuDate)).format(
                                        "MM/DD/YYYY"
                                      ) ===
                                      moment(new Date(damm.orderByDate)).format("MM/DD/YYYY")
                                    ? new Date(0, 0, 0, 3 + 12, 30)
                                    : ""
                                }
                                onError={(reason, value) => handleTimeError(index, reason)}
                                renderInput={(params) => (
                                  <TextField
                                    style={{ backgroundColor: "#f7f7f7" }}
                                    {...params}
                                  />
                                )}
                              />
                            )}
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="col-md-1 col-3 all-dish-item all-items-delete">
                        <div className="">
                          <a
                            type="button"
                            className="delete-btn hvr-sweep-to-right"
                            data-bs-toggle="modal"
                            data-bs-target="#delete-menu"
                            onClick={() => removeFormFields(index)}
                          >
                            <i className="ri-delete-bin-fill"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="mt-5 btn-group-add d-flex mb-3">
            {menuDetails?.length !== 0 &&
            menuDetails?.length !== undefined &&
            location.state.status != 5 ? (
              <>
                <button
                  className="btn purple-btn min-height hvr-sweep-to-right  me-3"
                  onClick={submit}
                  disabled={isDisabled}
                >
                  Save
                </button>{" "}
              </>
            ) : (
              ""
            )}
            {location.state.status != 5 ? (
              <a
                className="btn purple-btn min-height hvr-sweep-to-right trans-btn"
                onClick={() => addDish()}
              >
                Add More Dishes
              </a>
            ) : (
              <a className="btn purple-btn min-height hvr-sweep-to-right trans-btn">
                Update
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AddMenuDetails;
