import react from "react";
import { Outlet } from "react-router-dom";
import InnerHeader from "../component/dashboard/InnerHeader";
import SideBar from "../component/dashboard/SideBar";
export default function DashBoardLayout() {
  return (
    <>
      <InnerHeader />
      <div className="wraper-inner padding-head">
        <div className="inner-pages">
          {/* <div> */}
          <SideBar />
          {/* </div> */}
        </div>
      </div>
      <div className="screen-div-info">
        <ul className="info-list">
          <li>
            <a href="tel:+1 804-201-5042">
              <i className="ri-phone-line"></i>
              <span>+1 804-201-5042</span>
            </a>
          </li>
          <li>
            <a href="mailto:hi@wannaeat.com">
              <i className="ri-mail-line"></i>
              <span>hi@wannaeat.com</span>
            </a>
          </li>
        </ul>
      </div>
      <Outlet />
      {/* {props.children} */}
    </>
  );
}
