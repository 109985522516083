import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { ChefFaqURL, ImageURL, UserFaqURL } from "../component/config/config";
import axios from "axios";
import { Typography } from "@material-ui/core";
const contactBanner = ImageURL + "assets/images/contact-banner.png";
const joinIcon = ImageURL + "assets/images/join-icon.png";
const chefIcon = ImageURL + "assets/images/chef-icon.png";

const Faq = () => {
  const [chefFaq, setChefFaq] = useState();
  const [userFaq, setUserFaq] = useState();

  const chefFetch = async () => {
    const response = await axios.post(ChefFaqURL, {});
    setChefFaq(response.data.data);
  };
  const userFetch = async () => {
    const res = await axios.post(UserFaqURL, {});
    setUserFaq(res.data.data);
  };

  useEffect(() => {
    chefFetch();
    userFetch();
  }, []);

  return (
    <>
      <div className="wraper-inner padding-head">
        <div className="inner-pages">
          <div className="inner-banner inner-space">
            <figure>
              <img src={contactBanner} />
            </figure>
            <div className="container">
              <div className="banner-inner-content ">
                <h4>Frequently Asked Questions</h4>
              </div>
            </div>
          </div>
          <section className="faq-inner space-similer ">
            <div className="container">
              <ul
                className="nav nav-tabs faq-tabs inner-tabs-link d-flex justify-content-center"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item Customer-active" role="presentation">
                  <button
                    className="nav-link customer-active active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <span>
                      <img src={joinIcon} />
                    </span>{" "}
                    FAQs
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link chef-active active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"><span><img src={chefIcon} /></span> For Chefs</button>
                                </li> */}
              </ul>
              <div className="tab-content" id="myTabContent">
                {/* <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    {chefFaq?.map((daa, ind) => {
                                        return (
                                            <div className="accordion" id="accordionExample" key={ind}>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={"headingOne" + ind}>
                                                        <button style={{ wordBreak: "break-all" }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + ind} aria-expanded="true" aria-controls="collapseOne">
                                                            {daa.question}
                                                        </button>
                                                    </h2>
                                                    <div id={"collapseOne" + ind} className="accordion-collapse collapse " aria-labelledby={"headingOne" + ind} data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <p>
                                                                <Typography style={{ textAlign: "left", fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: daa?.answer }} variant="h6"></Typography>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div> */}
                <div
                  className="tab-pane fade show active "
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {userFaq?.map((data, index) => {
                    return (
                      <div
                        className="accordion"
                        id="accordionExample"
                        key={index}
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={"headingOne" + index}
                          >
                            <button
                              style={{ wordBreak: "break-all" }}
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapseOne" + index}
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              {data.question}
                            </button>
                          </h2>
                          <div
                            id={"collapseOne" + index}
                            className="accordion-collapse collapse "
                            aria-labelledby={"headingOne" + index}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <Typography
                                  style={{
                                    textAlign: "left",
                                    fontSize: "16px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: data?.answer,
                                  }}
                                  variant="h6"
                                ></Typography>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
