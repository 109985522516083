import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./Store/Store";
import Routing from "./Routes";
import { ToastContainer, Slide } from "react-toastify";
import ScrollToTop from "./component/scrollToTop";
import MetaPixel from "./utility/MetaPixel";
// import useSecretManager from "./hooks/SecretManager";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const helmetContext = {};
  // const apiKey = useSecretManager();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  store.subscribe(() => {
    localStorage.setItem("reduxStore", JSON.stringify(store.getState()));
  });
  // useEffect(() => {
  //   if (apiKey) {
  //     const script = document.createElement("script");
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey.GOOGLE_MAP_API_KEY}&libraries=places`;
  //     script.onload = () => setScriptLoaded(true);
  //     script.async = true;
  //     document.body.appendChild(script);
  //   }
  // }, [apiKey]);
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <ToastContainer limit={1} transition={Slide} autoClose={10000} />
        <Provider store={store}>
          <MetaPixel />
          <ScrollToTop />
          <Routing />
        </Provider>
      </HelmetProvider>
    </>
  );
}

export default App;
